import GroupsIcon from "@mui/icons-material/Groups";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import TagIcon from '@mui/icons-material/Tag';
import { useTheme } from "styled-components";
import { useGetAppSettingsQuery } from "../../../apis/configApi";
import { useAppSelector } from "../../../app/hooks/hooks";
import { selectEnvironment, selectIsWindowTooSmall } from "../../../app/slices/commonSlice";
import { TextButtonControl } from "../../../controls/Buttons/TextButtonControl";
import { ButtonsDiv } from "./SC/ButtonsDiv";
import { routing } from "../../../models/routingPaths";
import { useNavigate } from "react-router-dom";
import { useRouteHelper } from "../../../app/hooks/useRouteHelper";
import { HelpMenu } from "../HelpMenu/HelpMenu";
import { useMemo, useState } from "react";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { Environment } from "../../../models/environment";


export function ActionButtons() {
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);
  const theme = useTheme();
  const { data: config } = useGetAppSettingsQuery(null);
  const navigate = useNavigate();

  const { currentPath } = useRouteHelper();
  const getColors = (route: string) => currentPath.includes(route);
  const [helpAnchorEl, setHelpAnchorEl] = useState<null | HTMLElement>(null);
  const environment = useAppSelector(selectEnvironment);

  const isTestEnvironment = useMemo(() => { return environment === Environment.Test }, [environment])

  return (
    <ButtonsDiv>
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.verticalButtonHeight}
        icon={<PeopleIcon fontSize="small" />}
        id="header-action-publishers"
        tooltipText={TooltipMsg.Publishers}
        text="Publishers"
        isVertical={true}
        isVisible={true}
        isHighlight={getColors(routing.publishers)}
        isCompactView={isWindowTooSmall}
        onClick={() => navigate(routing.publishers)}
        removeGap={true}
      />
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.verticalButtonHeight}
        icon={<GroupsIcon fontSize="small" />}
        id="header-action-access-groups"
        tooltipText={TooltipMsg.AccessGroupsManagement}
        text="Access groups"
        onClick={() => {
          if (config) {
            window.open(config.accessGroupsAppUrl, "_blank");
          }
        }}
        isVertical={true}
        isCompactView={isWindowTooSmall}
        isVisible={true}
        removeGap={true}
      />
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.verticalButtonHeight}
        icon={<SettingsIcon fontSize="small" />}
        id="header-action-settings"
        tooltipText={TooltipMsg.CategorySettings}
        text="Settings"
        isVertical={true}
        isVisible={true}
        isHighlight={getColors(routing.settings)}
        isCompactView={isWindowTooSmall}
        onClick={() => navigate(routing.settings)}
        removeGap={true}
      />
      {isTestEnvironment && <TextButtonControl
        colors="secondary"
        height={theme.shapes.verticalButtonHeight}
        icon={<TagIcon fontSize="medium" />}
        id="header-action-settings"
        tooltipText="Open Tags management view"
        text="Tags"
        isVertical={true}
        isVisible={true}
        isHighlight={getColors(routing.tags)}
        isCompactView={isWindowTooSmall}
        onClick={() => navigate(routing.tags)}
        removeGap={true}
      />}
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.verticalButtonHeight}
        onClick={(e) => setHelpAnchorEl(e.currentTarget)}
        icon={<HelpCenterIcon fontSize="small" />}
        id="change-grouping-button"
        tooltipText={TooltipMsg.HelpMaterials}
        text="Help"
        isVertical={true}
        isCompactView={isWindowTooSmall}
        isVisible={true}
        removeGap={true}
      />
      <HelpMenu anchorEl={helpAnchorEl} onClose={() => setHelpAnchorEl(null)} />
      <TextButtonControl
        colors="secondary"
        height={theme.shapes.verticalButtonHeight}
        icon={<ThumbUpAltIcon fontSize="small" />}
        id="feedback-button"
        tooltipText={TooltipMsg.Feedback}
        text="Feedback"
        isVertical={true}
        isCompactView={isWindowTooSmall}
        isVisible={true}
        removeGap={true}
        onClick={() => window.open("https://forms.office.com/e/UUKvSiaQXa", "Abb Library Publishing Interface Feedback")}
      />
    </ButtonsDiv>
  );
}
