import { DialogContent, DialogActions } from "@mui/material";
import { IconButtonControl } from "../../../../controls/Buttons/IconButtonControl";
import { DialogButton } from "../../../../controls/Dialog/SC/DialogButton";
import { StyledDialog } from "../../../../controls/Dialog/SC/StyledDialog";
import { StyledDialogTitle } from "../../../../controls/Dialog/SC/StyledDialogTitle";
import { IconButtonControlDiv } from "../../../details/contextMenu/SC/IconButtonControlDiv";
import { StyledDialogContentText } from "../../../details/selfHelpPopup/SC/StyledDialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";

export interface WarningPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export function WarningPopup(props: WarningPopupProps) {
  return (
    <StyledDialog
      id={"set-warning-dialog"}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <StyledDialogTitle id={"set-warning-dialog-title"}>
        {"Category has unsaved changes"}
        <IconButtonControlDiv>
          <IconButtonControl
            id={"set-warning-dialog-close-button"}
            popupText={TooltipMsg.Close}
            onClick={props.onClose}
            icon={<CloseIcon fontSize="inherit" />}
          />
        </IconButtonControlDiv>
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id="set-warning-dialog-description">
          All changes will be lost. Are you sure you want to change category?
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton color="primary" onClick={props.onConfirm} autoFocus>
          {"Confirm"}
        </DialogButton>
        <DialogButton
          id={"set-deleted-dialog-cancel-button"}
          color="secondary"
          onClick={props.onClose}
        >
          {"Cancel"}
        </DialogButton>
      </DialogActions>
    </StyledDialog>
  );
}
