import CategoriesGroupColumn from "../Categories/CategoriesGroupColumn";
import { TagOwnersGroupColumn } from "../TagOwners/TagOwnersGroupColumn";
import { TagInfo } from "../TagValues/TagInfo";
import { TagValues } from "../TagValues/TagValues";
import { ColumnDiv } from "./SC/ColumnDiv";
import { DashboardRowDiv } from "./SC/DashboardRowDiv";
import { DashboardDiv } from "./SC/DashboardDiv";
import { ValuesRowDiv } from "./SC/ValuesRowDiv";

export function TagsDashboard() {
    return (
        <DashboardDiv>
            <TagInfo
                primaryInfo={"Mounting Arrangement"}
                secondaryInfo={"multi value tag"}
            />
            <DashboardRowDiv>
                <ValuesRowDiv>
                    <TagValues />
                </ValuesRowDiv>
                <ColumnDiv>
                    <CategoriesGroupColumn />
                    <TagOwnersGroupColumn />
                </ColumnDiv>
            </DashboardRowDiv>
        </DashboardDiv>
    );
}