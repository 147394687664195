import { InformationControl } from "../../../controls/InformationControl/InformationControl";


export function NoCategoriesFound() {
  return (
    <InformationControl
      id={"no-categories-found"}
      title="Nothing has been found"
      description="This access group is not used in any category."
    />
  );
}
