import { IconButton, Avatar } from "@mui/material";
import { StyledListItem } from "../SC/StyledListItem";
import { StyledListItemAvatar } from "./SC/StyledListItemAvatar";
import TagIcon from "@mui/icons-material/Tag";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledListItemText } from "./SC/StyledListItemText";

interface TagValueInfoProps {
  name: string;
}

export function TagValueInfo(props: TagValueInfoProps) {
  return (
    <StyledListItem
      secondaryAction={
        <IconButton edge="end" aria-label="delete">
          <DeleteIcon
            fontSize="small"
            style={{ fontSize: "18px" }}
          />
        </IconButton>
      }
    >
      <StyledListItemAvatar>
        <Avatar
          sx={{ width: 24, height: 24 }}
        >
          <TagIcon
            fontSize="small"
            style={{ fontSize: "18px" }}
          />
        </Avatar>
      </StyledListItemAvatar>
      <StyledListItemText primary={props.name} />
    </StyledListItem>
  );
}
