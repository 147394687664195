import { documentParamsLocalStorageKey } from "../../hooks/localStorage";
import { DocumentsColumnType } from "../../../models/documentList/types/DocumentColumnType";

export const documentListCompactViewWidth = 950;
export const documentHeaderLeftPadding = 12;
export const documentHeaderRightPadding = 0;

export const checkboxDefaultWidth = 35;
export const chevronDefaultWidth = 44;
export const workflowDefaultWidth = 12;
export const optionsDefaultWidth = 30;
export const scrollDefaultWidth = 16;
export const infoDefaultWidth = 42;
export const revisionDefaultWidth = 42;
export const securityLevelDefaultWidth = 68;
export const publishedDefaultWidth = 92;
export const documentNumberDefaultWidth = 124;
export const titleDefaultWidth = 439;
export const documentKindDefaultWidth = 76;
export const languageDefaultWidth = 82;
export const partDefaultWidth = 72;
export const publisherDefaultWidth = 110;

export const checkboxMinWidth = 35;
export const chevronMinWidth = 44;
export const workflowMinWidth = 12;
export const optionsMinWidth = 30;
export const infoMinWidth = 54;
export const revisionMinWidth = 32;
export const securityLevelMinWidth = 60;
export const publishedMinWidth = 60;
export const documentNumberMinWidth = 64;
export const titleMinWidth = 60;
export const documentKindMinWidth = 60;
export const languageMinWidth = 60;
export const partMinWidth = 60;
export const publisherMinWidth = 60;

export const contentMinWidth =
  checkboxMinWidth +
  chevronMinWidth +
  workflowMinWidth +
  optionsMinWidth +
  infoMinWidth +
  revisionMinWidth +
  securityLevelMinWidth +
  publishedMinWidth +
  documentNumberMinWidth +
  titleMinWidth +
  documentKindMinWidth +
  languageMinWidth +
  partMinWidth +
  publisherMinWidth;

export enum Density {
  Compact = "Compact",
  Standard = "Standard",
  Comfort = "Comfort",
}

export interface DocumentsParams {
  pageSize: number;
  density: Density;
}

export const initialPageNumber = 1;

export const defaultDensity = Density.Standard;
export const defaultDocumentParams = {
  pageSize: 20,
  density: defaultDensity,
};

export function getInitialDocumentsParams() {
  try {
    const value = localStorage.getItem(documentParamsLocalStorageKey);
    if (value) {
      return JSON.parse(value) as DocumentsParams;
    }
    return defaultDocumentParams;
  } catch {
    return defaultDocumentParams;
  }
}

export interface DocumentsColumn {
  isResizable: boolean;
  isSortable: boolean;
  title?: string;
  fullTitle?: string;
  size: number;
  minSize: number;
  defaultSize: number;
  type: DocumentsColumnType;
  isFiltered: boolean;
  isVisible: boolean;
}
export interface ColumnWidthChange {
  type: DocumentsColumnType;
  resizeValue: number;
}

export const defaultDocumentColumns = [
  {
    type: DocumentsColumnType.Chevron,
    isResizable: false,
    isSortable: false,
    size: chevronDefaultWidth,
    minSize: chevronMinWidth,
    defaultSize: chevronDefaultWidth,
    isFiltered: false,
    isVisible: true,
  },
  {
    title: "Number",
    fullTitle: "Document number",
    type: DocumentsColumnType.DocumentNumber,
    isResizable: true,
    isSortable: true,
    size: documentNumberDefaultWidth,
    minSize: documentNumberMinWidth,
    defaultSize: documentNumberDefaultWidth,
    isFiltered: false,
    isVisible: true,
  },
  {
    title: "Title",
    fullTitle: "Title",
    type: DocumentsColumnType.Title,
    isResizable: true,
    isSortable: true,
    size: titleDefaultWidth,
    minSize: titleMinWidth,
    defaultSize: titleDefaultWidth,
    isFiltered: false,
    isVisible: true,
  },
  {
    title: "Doc. Kind",
    fullTitle: "Document Kind",
    type: DocumentsColumnType.DocumentKind,
    isResizable: true,
    isSortable: false,
    size: documentKindDefaultWidth,
    minSize: documentKindMinWidth,
    defaultSize: documentKindDefaultWidth,
    isFiltered: true,
    isVisible: true,
  },
  {
    title: "Language",
    fullTitle: "Language",
    type: DocumentsColumnType.Language,
    isResizable: true,
    isSortable: false,
    size: languageDefaultWidth,
    minSize: languageMinWidth,
    defaultSize: languageDefaultWidth,
    isFiltered: true,
    isVisible: true,
  },
  {
    title: "Part",
    fullTitle: "Part",
    type: DocumentsColumnType.Part,
    isResizable: true,
    isSortable: false,
    size: partDefaultWidth,
    minSize: partMinWidth,
    defaultSize: partDefaultWidth,
    isFiltered: true,
    isVisible: true,
  },
  {
    title: "Rev.",
    fullTitle: "Revision",
    type: DocumentsColumnType.Revision,
    isResizable: true,
    isSortable: false,
    size: revisionDefaultWidth,
    minSize: revisionMinWidth,
    defaultSize: revisionDefaultWidth,
    isFiltered: true,
    isVisible: true,
  },
  {
    title: "Published",
    fullTitle: "Published",
    type: DocumentsColumnType.Published,
    isResizable: true,
    isSortable: true,
    size: publishedDefaultWidth,
    minSize: publishedMinWidth,
    defaultSize: publishedDefaultWidth,
    isFiltered: true,
    isVisible: true,
  },
  {
    title: "Security",
    fullTitle: "Security level",
    type: DocumentsColumnType.SecurityLevel,
    isResizable: true,
    isSortable: false,
    size: securityLevelDefaultWidth,
    minSize: securityLevelMinWidth,
    defaultSize: securityLevelDefaultWidth,
    isFiltered: true,
    isVisible: true,
  },
  {
    title: "Publisher",
    fullTitle: "Publisher",
    type: DocumentsColumnType.Publisher,
    isResizable: true,
    isSortable: false,
    size: publisherDefaultWidth,
    minSize: publisherMinWidth,
    defaultSize: publisherDefaultWidth,
    isFiltered: true,
    isVisible: true,
  },
  {
    title: "Info",
    fullTitle: "Info",
    type: DocumentsColumnType.Info,
    isResizable: true,
    isSortable: false,
    size: infoDefaultWidth,
    minSize: infoMinWidth,
    defaultSize: infoDefaultWidth,
    isFiltered: true,
    isVisible: true,
  },
  {
    type: DocumentsColumnType.Options,
    isResizable: false,
    isSortable: false,
    size: optionsDefaultWidth,
    minSize: optionsMinWidth,
    defaultSize: optionsDefaultWidth,
    isFiltered: false,
    isVisible: true,
  },
];
