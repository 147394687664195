import { InformationControl } from "../../../controls/InformationControl/InformationControl";


export function NoOwnersFound() {
  return (
    <InformationControl
      id={"no-admins-found"}
      title="Nothing has been found"
      description="This group does not have any administrators assigned or administrators do not match the search query applied."
    />
  );
}
