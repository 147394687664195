import { useTheme } from "styled-components";
import SaveIcon from "@mui/icons-material/Save";
import { TextButtonControl } from "../../../controls/Buttons/TextButtonControl";

interface SaveChangesButtonProps {
  isEdited?: boolean;
  permissionMissing?: boolean;
  isLoading: boolean;
  isErrors: boolean;
  saveSettingsHandler: () => void;
}

export function SaveChangesButton(props: SaveChangesButtonProps) {
  const theme = useTheme();

  return (
    <TextButtonControl
      id={"tags-save-button"}
      colors={"primary"}
      height={theme.shapes.primaryButtonHeight}
      text={"Save changes"}
      onClick={props.saveSettingsHandler}
      isCompactView={false}
      isVertical={false}
      isVisible={true}
      disabled={
        !props.isEdited ||
        props.permissionMissing ||
        props.isLoading ||
        props.isErrors
      }
      icon={<SaveIcon fontSize="small" />}
    />
  );
}
