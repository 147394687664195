
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { SelfHelpButtonControl } from "../../../controls/Buttons/SelfHelpButtonControl";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { CloseButton } from "../../details/contextMenu/buttons/CloseButton";
import { SaveChangesButton } from "./SaveChangesButton";
import { ButtonsDiv } from "./SC/ButtonsDiv";
import { HeaderDiv } from "./SC/HeaderDiv";

export function TagsHeader() {
    return (
        <HeaderDiv id="header-div">
            <ButtonsDiv>
                <SaveChangesButton
                    saveSettingsHandler={() => console.log("saveSettingsHandler")}
                    isLoading={false}
                    isErrors={false}
                />
                <CloseButton />
                <SelfHelpButtonControl
                    currentSelfHelp={CurrentSelfHelp.Tags}
                    page={SelfHelpPage.Tags}
                    section={SectionName.Tags}
                />
            </ButtonsDiv>
        </HeaderDiv>
    );
}
