import { useCallback, useEffect } from "react";
import {
  useLazyGetUncompletedSectionsQuery,
  useMarkSectionAsCompletedMutation,
} from "../../apis/selfHelpApi";
import { SnackbarType } from "../../models/snackbar";
import { openSnackbar } from "../helpers/snackBarHelper";
import { setUncompletedSections } from "../slices/selfHelpSlice";
import { useAppDispatch } from "./hooks";
import { SelfHelpSectionIdentifier } from "../../models/selfHelp/selfHelpSection";
import { SnackbarMsg } from "../../controls/Snackbar/SnackbarMessages";

function useGetSelfHelpUncompletedSections() {
  const dispatch = useAppDispatch();
  const [
    getUncompletedSections,
    { data: uncompletedSections, isError: uncompletedSectionsError },
  ] = useLazyGetUncompletedSectionsQuery();

  const [
    markSection,
    {
      isSuccess: markAsCompletedSuccess,
      isError: markAsCompletedError,
      isLoading: markAsCompletedFetching,
    },
  ] = useMarkSectionAsCompletedMutation();

  const fetchUncompletedSections = useCallback(() => {
    void getUncompletedSections(null);
  }, [getUncompletedSections]);

  const markSectionAsCompleted = useCallback(
    (arg: SelfHelpSectionIdentifier) => {
      void markSection(arg);
    },
    [markSection]
  );

  useEffect(() => {
    if (uncompletedSections) {
      dispatch(setUncompletedSections(uncompletedSections));
    }
  }, [dispatch, uncompletedSections]);

  useEffect(() => {
    if (uncompletedSectionsError) {
      openSnackbar(
        dispatch,
        SnackbarMsg.UncompletedSectionsError,
        SnackbarType.error
      );
    }
  }, [uncompletedSectionsError, dispatch]);

  useEffect(() => {
    if (markAsCompletedSuccess) {
      fetchUncompletedSections();
      openSnackbar(
        dispatch,
        SnackbarMsg.SectionMarkSuccess,
        SnackbarType.success
      );
    }
  }, [
    dispatch,
    fetchUncompletedSections,
    markAsCompletedSuccess,
    markAsCompletedFetching,
  ]);

  useEffect(() => {
    if (markAsCompletedError) {
      openSnackbar(dispatch, SnackbarMsg.SectionMarkError, SnackbarType.error);
    }
  }, [dispatch, markAsCompletedError]);

  return {
    fetchUncompletedSections,
    markSectionAsCompleted,
  };
}

export default useGetSelfHelpUncompletedSections;
