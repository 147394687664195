import { StyledLockIcon } from "../../../../../components/details/sections/SC/StyledLockIcon";
import {
  StyledElectricalServicesIcon,
  StyledLinkIcon,
  StyledPublicIcon,
} from "../../../../../components/documents/documentsList/documentListItem/cells/styledComponents/DocumentInfoIcons";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { StyledLi } from "../../../SC/StyledLi";

export function InfoColumnStep() {
  return (
    <StepContentDiv>
      <Paragraph>
        The &quot;Info&quot; column can display icons to indicate certain
        conditions:
        <ul>
          <StyledLi>
            <StyledLockIcon />
            <span>
              A padlock icon is shown when the user does not have access to any
              document category.
            </span>
          </StyledLi>
          <StyledLi>
            <StyledLinkIcon />
            <span>
              A chain link icon is displayed when the user does not have access
              to the main category of the document.
            </span>
          </StyledLi>
          <StyledLi>
            <StyledPublicIcon />
            <span>
              A globe icon represents that the document has country restrictions
              applied.
            </span>
          </StyledLi>
          <StyledLi>
            <StyledElectricalServicesIcon />
            <span>
              A plug icon represents that the document has been uploaded from an
              external application and can only be managed through that
              application.
            </span>
          </StyledLi>
        </ul>
      </Paragraph>
    </StepContentDiv>
  );
}
