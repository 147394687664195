import { TextFieldInput } from "../../../../controls/TextField/TextFieldInput";
import { TagTypeSearchDiv } from "./SC/TagTypeSearchDiv";

interface TagTypeSearchProps {
  isMinimized: boolean;
}

export function TagTypeSearch({
  isMinimized,
}: TagTypeSearchProps) {


  if (isMinimized) {
    return <></>;
  }

  return (
    <TagTypeSearchDiv id="tag-type-search-input-div">
      <TextFieldInput
        id="tag-type-search-textfield"
        label=""
        placeholder="Type tag type name to search..."
        onClick={() => console.log("search")}
        initialValue={""}
        trimEntries={false}
      />
    </TagTypeSearchDiv>
  );
}
