import { ContentDiv } from "./SC/ContentDiv";
import { ReflexContainer, ReflexElement } from "../../controls/Reflex";
import { navigationDefaultWidth } from "../../app/slices/models/contentModels";
import { useNavigationResize, minimizedSize } from "./useNavigationResize";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { DialogContentDiv } from "../details/sections/categories/addCategories/SC/DialogContentDiv";
import {
  publisherCategoriesApi,
  useLazyGetOwnerCategoriesQuery,
} from "../../apis/publisherCategoriesApi";
import { useCallback, useEffect, useRef, useState } from "react";
import { CategoryTree } from "../cms/Config/navigation/CategoryTree";
import {
  clearOwnerCategories,
  removeSelectedNode,
  selectIsNavigationMinimized,
  selectNavigationWidth,
  selectSelectedNode,
  selectSettingsWidth,
  setIsNavigationMinimized,
  updateOwnerCategories,
} from "../../app/slices/ownerCategoryTreeSlice";
import { ToggleNavigation } from "../documentsNavigation/ToggleNavigation";
import { Settings } from "../cms/Settings";
import {
  selectIsEdited,
  setCategoryId,
  setInitialSettingsValues,
  setReloadSettings,
  setSelectedCategoryId,
  setSelectedCategoryName,
} from "../../app/slices/publisherCategoriesSettingsSlice";
import { NoResourceFound } from "../documents/documentsList/noDocumentsFound/NoResourceFound";
import { Header } from "../cms/Config/Header";
import { DashboardDiv } from "../cms/SC/DashboardDiv";
import { Footer } from "../footer/Footer";
import { SettingsDiv } from "../cms/SC/SettingsDiv";
import { useDictionaryListsFetch } from "../../app/hooks/settings/useDictionaryListsFetch";
import { ErrorDisplay } from "../../app/errorHandling/ErrorDisplay";
import useWindowDimensions from "../../app/hooks/useWindowDimensions";
import { StyledReflexSplitter } from "./SC/StyledReflexSplitter";
import { TabDiv } from "../publishers/SC/TabDiv";
import { SectionName } from "../../app/slices/models/documentDetailsModels";
import { CurrentSelfHelp } from "../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../models/selfHelp/selfHelpSection";
import { WarningPopup } from "../cms/Config/popups/WarningPopup";

const initialCategoriesArgs = {
  maxDepth: 1,
  parentCategoryId: "Root",
  includeHighestParent: true,
  maxCategoryLevelShow: 0,
};

const abortReason = "Page changed";

export function SettingsView() {
  const { onStartResize, onStopResize, onNavigationStopResize } =
    useNavigationResize();

  const { windowWidth } = useWindowDimensions();

  const hiddenNavigationWitdh = 0;
  const dispatch = useAppDispatch();
  const navigationWidth = useAppSelector(selectNavigationWidth);
  const isMinimized = useAppSelector(selectIsNavigationMinimized);
  const contentWidth = useAppSelector(selectSettingsWidth);
  const categoryId = useAppSelector(selectSelectedNode);
  const firstOpen = useRef(true);
  const abortController = useRef<AbortController>();
  const [popupOpen, setPopupOpen] = useState(false);
  const [nextCategory, setNextCategory] = useState("");
  const settingsIsEdited = useAppSelector(selectIsEdited);

  const { isLoading, isError } = useDictionaryListsFetch();

  const [getOwnerCategories, { data, isFetching, isSuccess }] =
    useLazyGetOwnerCategoriesQuery();

  const fetchCategories = useCallback(() => {
    void getOwnerCategories({
      ...initialCategoriesArgs,
      signal: abortController.current?.signal,
    });
  }, [getOwnerCategories]);

  const onChangeSizeClick = () => {
    dispatch(setIsNavigationMinimized(!isMinimized));
  };

  const onCategoryChangeHandler = (
    categoryId: string,
    categoryName: string
  ) => {
    if (settingsIsEdited) {
      setPopupOpen(true);
      setNextCategory(categoryId);
    } else {
      setSelectedCategory(categoryId, categoryName);
    }
  };

  const confirmWarningDialog = () => {
    dispatch(setCategoryId(categoryId));
    setSelectedCategoryId(nextCategory);
    setNextCategory("");
    setPopupOpen(false);
  };

  const setSelectedCategory = (categoryId: string, categoryName: string) => {
    dispatch(setInitialSettingsValues());
    dispatch(setCategoryId(categoryId));

    dispatch(setSelectedCategoryId(categoryId));
    dispatch(setSelectedCategoryName(categoryName));
  };

  useEffect(() => {
    if (firstOpen.current) {
      fetchCategories();
      firstOpen.current = false;
    }
  }, [fetchCategories]);

  useEffect(() => {
    if (data && data.length > 0) {
      dispatch(updateOwnerCategories(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    abortController.current = new AbortController();

    return () => {
      publisherCategoriesApi.util.resetApiState();
      dispatch(clearOwnerCategories(true));
      dispatch(removeSelectedNode());
      abortController.current?.abort(abortReason);
    };
  }, [dispatch]);

  const content = () => {
    let result = <></>;
    if (!categoryId) {
      result = (
        <>
          <TabDiv id="content-tab-div">
            <NoResourceFound
              headerMessage="Select category"
              contentMessage="Please select Level 0 category in the left menu to display its settings."
              headerId="cms-no-category-selected"
            />
          </TabDiv>
        </>
      );
    } else {
      if (isError) {
        result = (
          <ErrorDisplay
            errorId={"cms-category-settings-error"}
            errorMessageTitle={"Something went wrong"}
            errorDescription={"Settings request failed"}
            refreshFunction={() => {
              dispatch(setCategoryId(categoryId));
              dispatch(setReloadSettings(true));
            }}
            showDash={false}
            showReloadButton={true}
          />
        );
      } else {
        result = <Settings isLoading={isLoading} />;
      }
    }

    return (
      <DashboardDiv id="dashboard-div">
        <SettingsDiv>
          <Header isLoading={isLoading} />
          {result}
        </SettingsDiv>
        <Footer />
      </DashboardDiv>
    );
  };

  return (
    <ContentDiv id="content-div">
      <ReflexContainer orientation="vertical" windowResizeAware={true}>
        <ReflexElement
          flex={isMinimized ? hiddenNavigationWitdh : navigationWidth}
          onStopResize={onNavigationStopResize}
          className="left-pane"
          minSize={isMinimized ? minimizedSize : navigationDefaultWidth}
          maxSize={isMinimized ? minimizedSize : windowWidth / 3}
        >
          <DialogContentDiv>
            <CategoryTree
              showSearchbar={false}
              colorCategoriesByLevel={0}
              isMinimized={isMinimized}
              onToggleClick={onChangeSizeClick}
              isLoading={isFetching}
              isSuccess={isSuccess}
              maxCategoryLevelShow={0}
              onCategorySelect={onCategoryChangeHandler}
              currentSelfHelp={CurrentSelfHelp.CmsNavigation}
              selfHelpPage={SelfHelpPage.Cms}
              selfHelpSectionName={SectionName.CmsNavigation}
            />
            <ToggleNavigation
              onToggleClick={onChangeSizeClick}
              isMinimized={isMinimized}
            />
          </DialogContentDiv>
        </ReflexElement>

        <StyledReflexSplitter
          $isMinimized={isMinimized}
          onStartResize={onStartResize}
          onStopResize={onStopResize}
        />
        <ReflexElement
          flex={isMinimized ? 1 - hiddenNavigationWitdh : contentWidth}
          className="right-pane"
        >
          {content()}
        </ReflexElement>
      </ReflexContainer>
      <WarningPopup
        isOpen={popupOpen}
        onConfirm={confirmWarningDialog}
        onClose={() => setPopupOpen(false)}
      />
    </ContentDiv>
  );
}
