import { ListItemAvatar } from "@mui/material";

import TagIcon from "@mui/icons-material/Tag";
import { ItemAvatarDiv } from "../../publishers/publisherCategories/SC/ItemAvatarDiv";
import { StyledListItem } from "../SC/StyledListItem";
import { StyledAvatar } from "../SC/StyledAvatar";
import { StyledListItemText } from "../SC/StyledListItemText";

export interface PublisherInfoProps {
  primaryInfo: string | null;
  secondaryInfo: string | null;
}

export function TagInfo(props: PublisherInfoProps) {
  return (
    <ItemAvatarDiv>
      <StyledListItem>
        <ListItemAvatar>
          <StyledAvatar>
            <TagIcon />
          </StyledAvatar>
        </ListItemAvatar>
        <StyledListItemText
          primary={props.primaryInfo}
          secondary={props.secondaryInfo}
        />
      </StyledListItem>
    </ItemAvatarDiv>
  );
}
