import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tagsViewLocalStorageKey } from "../hooks/localStorage";
import { RootState } from "../store";

export interface TagsViewParams {
  navigationFlex: number;
  dashboardFlex: number;
  isMinimized: boolean;
}

export interface TagsViewState {
  viewState: TagsViewParams;
}

const defaultSplitViewParams: TagsViewParams = {
  navigationFlex: 0.2,
  dashboardFlex: 0.8,
  isMinimized: false,
};

export function getInitialParams() {
  try {
    const value = localStorage.getItem(tagsViewLocalStorageKey);
    if (value) {
      return JSON.parse(value) as TagsViewParams;
    }

    return defaultSplitViewParams;
  } catch {
    return defaultSplitViewParams;
  }
}

const initialState: TagsViewState = {
  viewState: getInitialParams(),
};

export const tagsViewSlice = createSlice({
  name: "tagsView",
  initialState: initialState,
  reducers: {
    setNavigationFlex(state, action: PayloadAction<number>) {
      state.viewState.navigationFlex = action.payload;
    },
    setDashboardFlex(state, action: PayloadAction<number>) {
      state.viewState.dashboardFlex = action.payload;
    },
    setIsNavigationMinimized(state, action: PayloadAction<boolean>) {
      state.viewState.isMinimized = action.payload;
    },
  },
});

export const { setNavigationFlex, setDashboardFlex, setIsNavigationMinimized } =
  tagsViewSlice.actions;

export const selectDashboardFlex = (state: RootState) =>
  state.tagsView.viewState.dashboardFlex;

export const selectNavigationFlex = (state: RootState) =>
  state.tagsView.viewState.navigationFlex;

export const selectIsNavigationMinimized = (state: RootState) =>
  state.tagsView.viewState.isMinimized;

export default tagsViewSlice.reducer;
