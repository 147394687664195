import { useCallback } from "react";
import { useGetAppSettingsQuery } from "../../../apis/configApi";
import { ValidationStatus } from "../../../models/validationRule";
import { maxAttachmentNameLength } from "../../helpers/attachmentHelper";
import {
  selectIsDraftValidationOn,
  selectIsPublishValidationOn,
} from "../../slices/documentMetadataValidationSlice";
import { useAppSelector } from "../hooks";
import { useDocumentFlow } from "./useDocumentFlow";

export const nonEmptyRegex = () => {
  return new RegExp("^(?!\\s+$).+");
};

export const noNewLinesRegex = () => {
  return new RegExp("^[^\\r\\n]*$");
};

export const maxLengthRegex = (maxLength: number) => {
  return new RegExp(`^(?:[^\n]|\n){0,${maxLength}}$`);
};

const forbiddenChars = `?*/~"%#{}[]<>!^+:|\\`;

const createForbiddenCharRegex = (): RegExp => {
  const escapedChars = forbiddenChars.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"); //eslint-disable-line
  return new RegExp(`[${escapedChars}]`);
};

export default function useDocumentValidationRules() {
  const { isNewDocument } = useDocumentFlow();
  const isPublishValidationOn = useAppSelector(selectIsPublishValidationOn);
  const isDraftValidationOn = useAppSelector(selectIsDraftValidationOn);
  const { data: config } = useGetAppSettingsQuery(null);
  const regExp = config
    ? config.documentDetail.documentNumber.regExp
    : "([1-9][A-Z]{2,3}[\\d]{1,11})$|([1-9][A-Z]{2,3}[\\d]{1,6})(([A-HJ-NP-Z](\\d{1,6}))|([-](\\d{1,6}|[A-Z]{1,4}))$)";

  const getFileNameValidationRules = useCallback(
    (extension: string) => {
      const validFileLengthWithoutExtension =
        maxAttachmentNameLength - extension.length;

      return [
        {
          regExp: maxLengthRegex(validFileLengthWithoutExtension),
          message: `File name with extension is longer than ${maxAttachmentNameLength} characters`,
          validationType: ValidationStatus.error,
        },
        {
          regExp: noNewLinesRegex(),
          message: "File name cannot contain new lines",
          validationType: ValidationStatus.error,
        },
        {
          regExp: nonEmptyRegex(),
          message: "File and file name cannot be empty",
          isVisible: isPublishValidationOn || isDraftValidationOn,
          validationType: ValidationStatus.error,
        },
        {
          regExp: createForbiddenCharRegex(),
          message:
            'File name contains restricted characters: \\|+:^!<>{}[]#%" ~/*?',
          isVisible: isPublishValidationOn || isDraftValidationOn,
          omitText: "File and file name cannot be empty",
          forceNonVisibleValidation: true,
          successIsInvalid: true,
          validationType: ValidationStatus.error,
        },
      ];
    },
    [isDraftValidationOn, isPublishValidationOn]
  );

  const getTitleNameValidationRules = useCallback(() => {
    return [
      {
        regExp: maxLengthRegex(300),
        message: "Title is longer than 300 characters",
        validationType: ValidationStatus.error,
      },
      {
        regExp: noNewLinesRegex(),
        message: "Title cannot contain new lines",
        validationType: ValidationStatus.error,
      },
      {
        regExp: nonEmptyRegex(),
        message: "Title cannot be empty",
        isVisible: !isNewDocument || isPublishValidationOn,
        validationType: ValidationStatus.error,
      },
    ];
  }, [isNewDocument, isPublishValidationOn]);

  const getDocumentNumberValidationRules = useCallback(() => {
    return [
      {
        regExp: noNewLinesRegex(),
        message: "Document number cannot contain new lines",
        validationType: ValidationStatus.error,
      },
      {
        regExp: maxLengthRegex(100),
        message: "Document number is longer than 100 characters",
        validationType: ValidationStatus.error,
      },
      {
        regExp: new RegExp(
          `^[a-zA-Z0-9\\-_()\\./\\s]*[a-zA-Z0-9 \\-_()\\./\\s]*[a-zA-Z0-9\\-_()\\./\\s]*$`
        ),
        message: "Document number contains illegal characters",
        validationType: ValidationStatus.error,
      },
      {
        regExp: nonEmptyRegex(),
        message: "Document number cannot be empty",
        isVisible: !isNewDocument || isPublishValidationOn,
        validationType: ValidationStatus.error,
      },
      {
        regExp: new RegExp(regExp),
        message: (
          <>
            Document number is not{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://search.abb.com/library/Download.aspx?DocumentID=9ADA11&LanguageCode=en&DocumentPartId=&Action=Launch"
            >
              9ADA11
            </a>{" "}
            compliant
          </>
        ),
        validationType: ValidationStatus.warning,
      },
    ];
  }, [isNewDocument, isPublishValidationOn, regExp]);

  const getPartValidationRules = () => [
    {
      regExp: new RegExp("^(?!\\bMain\\b$).*$", "is"),
      message:
        "'Main' as a value is used in Download Center to display documents with empty Part ID. Please leave it empty if it should be displayed there as 'Main'.",
      validationType: ValidationStatus.error,
    },
    {
      regExp: noNewLinesRegex(),
      message: "Part cannot contain new lines",
      validationType: ValidationStatus.error,
    },
    {
      regExp: new RegExp("^(?!\\b_\\b$).*$", "is"),
      message: `Part contains illegal character _`,
      validationType: ValidationStatus.error,
    },
    {
      regExp: new RegExp("^(?!.*%).*", "is"),
      message: `Part contains illegal character %`,
      validationType: ValidationStatus.error,
    },
    {
      regExp: new RegExp("^(?![.]+$).*$"),
      message: `Part contains illegal character .`,
      validationType: ValidationStatus.error,
    },
    {
      regExp: maxLengthRegex(100),
      message: "Document part is longer than 100 characters",
      validationType: ValidationStatus.error,
    },
  ];

  const getSummaryValidationRules = useCallback(() => {
    return [
      {
        regExp: maxLengthRegex(2000),
        message: "Summary is too long. Maximum length is 2000 characters.",
        validationType: ValidationStatus.error,
      },
    ];
  }, []);

  const getProductsValidationRules = useCallback(() => {
    return [
      {
        message: "Max allowed length for product id is 50 characters",
        regExp: maxLengthRegex(50),
        validationType: ValidationStatus.error,
      },
    ];
  }, []);

  return {
    getFileNameValidationRules,
    getTitleNameValidationRules,
    getDocumentNumberValidationRules,
    getPartValidationRules,
    getSummaryValidationRules,
    getProductsValidationRules,
  };
}
