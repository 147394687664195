import { Tags } from "../tags/Tags";
import { ContentDiv } from "./SC/ContentDiv";

export function TagsView() {
  return (
    <ContentDiv id="content-div">
      <Tags />
    </ContentDiv>
  );
}
