import styled from "styled-components";

export const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 48px;
  height: 48px;
  font-size: ${(props) => props.theme.typography.fontSizes.large}px;
  font-weight: 700;
  color: ${(props) => props.theme.palettes.grayscale.dark};
  background-color: white;
  width: 100%;
  align-items: center;                    
`;
