/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { TagTypeSearch } from "./TagTypeSearch/TagTypeSearch";
import { selectIsNavigationMinimized, setIsNavigationMinimized } from "../../../app/slices/tagsViewSlice";
import { IconButtonControl } from "../../../controls/Buttons/IconButtonControl";
import { SelfHelpButtonControl } from "../../../controls/Buttons/SelfHelpButtonControl";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { CategoryTreeHeaderDiv } from "../../categoryNavigation/SC/CategoryTreeHeaderDiv";
import { CategoryTreeTitleSpan } from "../../cms/Config/navigation/SC/CategoryTreeTitleSpan";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { TagTypesDiv } from "./SC/TagTypesDiv";
import { TagType } from "./TagType/TagType";
import { Divider } from "@mui/material";
import { TypesDiv } from "./SC/TypesDiv";


export function TagTypes() {
    const dispatch = useAppDispatch();
    const isMinimized = useAppSelector(selectIsNavigationMinimized);

    const onToggleClick = useCallback(() => {
        dispatch(setIsNavigationMinimized(!isMinimized));
    }, [isMinimized, dispatch]);


    return (
        <TagTypesDiv>
            <CategoryTreeHeaderDiv>
                <CategoryTreeTitleSpan>Select Tag type</CategoryTreeTitleSpan>
                <SelfHelpButtonControl
                    currentSelfHelp={CurrentSelfHelp.Tags}
                    page={SelfHelpPage.Tags}
                    section={SectionName.Tags}
                />
                <IconButtonControl
                    id="navigation-collapse-button"
                    popupText={TooltipMsg.Hide}
                    icon={<KeyboardDoubleArrowLeftIcon fontSize="inherit" />}
                    onClick={onToggleClick}
                />
            </CategoryTreeHeaderDiv>
            <TagTypeSearch isMinimized={false} />
            <TypesDiv>
                <TagType primaryInfo={"Mounting Arrangement"} secondaryInfo={null} hasAccess={false} />
                <TagType primaryInfo={"Extended Product Types"} secondaryInfo={null} hasAccess={false} />
                <TagType primaryInfo={"Mounting Arrangement"} secondaryInfo={null} hasAccess={false} />
                <TagType primaryInfo={"Extended Product Types"} secondaryInfo={null} hasAccess={false} />
                <TagType primaryInfo={"Mounting Arrangement"} secondaryInfo={null} hasAccess={false} />
                <TagType primaryInfo={"Extended Product Types"} secondaryInfo={null} hasAccess={false} />
                <TagType primaryInfo={"Mounting Arrangement"} secondaryInfo={null} hasAccess={false} />
                <TagType primaryInfo={"Extended Product Types"} secondaryInfo={null} hasAccess={false} />
                <TagType primaryInfo={"Mounting Arrangement"} secondaryInfo={null} hasAccess={false} />
                <TagType primaryInfo={"Extended Product Types"} secondaryInfo={null} hasAccess={false} />
                <TagType primaryInfo={"Mounting Arrangement"} secondaryInfo={null} hasAccess={false} />
                <TagType primaryInfo={"Extended Product Types"} secondaryInfo={null} hasAccess={false} />
            </TypesDiv>


        </TagTypesDiv>
    );
}
