import TagIcon from "@mui/icons-material/Tag";
import { StyledAvatar } from "./SC/StyledAvatar";
import { StyledListItemText } from "./SC/StyledListItemText";
import { StyledListItem } from "./SC/StyledListItem";
import { ItemAvatarDiv } from "./SC/ItemAvatarDiv";
import { StyledListItemAvatar } from "./SC/StyledListItemAvatar";

export interface TagTypeProps {
    primaryInfo: string | null;
    secondaryInfo: string | null;
    hasAccess: boolean;
}

export function TagType(props: TagTypeProps) {
    return (
        <ItemAvatarDiv>
            <StyledListItem>
                <StyledListItemAvatar>
                    <StyledAvatar>
                        <TagIcon fontSize="small" />
                    </StyledAvatar>
                </StyledListItemAvatar>
                <StyledListItemText
                    primary={props.primaryInfo}
                    secondary={props.secondaryInfo}
                />
            </StyledListItem>
        </ItemAvatarDiv>
    );
}
