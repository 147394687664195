/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { CircularProgress } from "@mui/material";
import { useTheme } from "styled-components";
import { ErrorDisplay } from "../../../app/errorHandling/ErrorDisplay";
import { PaperControl } from "../../../controls/Paper/PaperControl";
import { PaperContentDiv } from "../../../controls/Paper/SC/PaperContentDiv";
import { PaperContentCenterDiv } from "../../publishers/tabs/categoryTab/publishers/TableControl/SC/PaperContentCenterDiv";
import { Breadcrumb } from "./Braedcrumb/Breadcrumb";
import { NoCategoriesFound } from "./NoCategoriesFound";
import { GroupsColumnDiv } from "./SC/GroupsColumnDiv";
import { GroupsList } from "./SC/GroupsList";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";

export interface Category {
    id: string;
    name: string;
    breadcrumb: string[]
}


const fetchCategories = () => { console.log("fetchCategories"); };
const isCategoriesFetching = false;
const isCategoriesError = false;
const categories: Category[] = [
    {
        breadcrumb: ["Category 1", "Category 2", "Category 3"],
        id: "1",
        name: "Category 3"
    },
    {
        breadcrumb: ["Category 1", "Category 2", "Category 3"],
        id: "2",
        name: "Category 3"
    },
    {
        breadcrumb: ["Category 1", "Category 2", "Category 3"],
        id: "3",
        name: "Category 3"
    }];

function CategoriesGroupColumn() {
    const theme = useTheme();

    const getContent = () => {
        if (isCategoriesFetching) {
            return (
                <PaperContentCenterDiv>
                    <CircularProgress
                        sx={{ color: theme.palettes.primary.main }}
                        size="30px"
                    />
                </PaperContentCenterDiv>
            );
        }

        if (isCategoriesError) {
            return (
                <PaperContentCenterDiv>
                    <ErrorDisplay
                        errorId={"categories"}
                        errorMessageTitle={"Something went wrong"}
                        errorDescription={"Error while loading categories."}
                        refreshFunction={fetchCategories}
                        showReloadButton={true}
                    />
                </PaperContentCenterDiv>
            );
        }

        if (categories?.length === 0) {
            return (
                <PaperContentCenterDiv id="categories-not-found-div">
                    <NoCategoriesFound />
                </PaperContentCenterDiv>
            );
        }

        return (
            <PaperContentDiv id="categories-content-div">
                <GroupsList id={"categories-list"} dense={true}>
                    {categories?.map((c) => (
                        <Breadcrumb
                            key={c.id}
                            categoryId={c.id}
                            isGray={false}
                            separator=">"
                            categories={c.breadcrumb}
                        />
                    ))}
                </GroupsList>
            </PaperContentDiv>
        );
    };

    return (
        <GroupsColumnDiv width={100} height={50} id={"categories-column"}>
            <PaperControl title={"Categories"} currentSelfHelp={CurrentSelfHelp.Tags} selfHelpPage={SelfHelpPage.Tags} selfHelpSectionName={SectionName.Tags} useHeader={true}>
                {getContent()}
            </PaperControl>
        </GroupsColumnDiv>
    );
}

export default CategoriesGroupColumn;
