import styled from "styled-components";

interface PaperHeaderDivProps {
  disabled?: boolean;
}

export const PaperHeaderDiv = styled.div<PaperHeaderDivProps>`
  font-weight: 700;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.palettes.grayscale.medium};
  justify-content: space-between;
  height: 34px;
  padding-right: 4px;
  padding-right: 4px;
  align-items: center;
  color: ${(props) =>
    props.disabled
      ? props.theme.palettes.grayscale.medium
      : props.theme.palettes.grayscale.darkest};
  font-size: ${(props) => props.theme.typography.fontSizes.headerT1}px;
`;
