import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { remove } from "lodash";
import { CurrentSelfHelp } from "../../models/CurrentSelfHelpType";
import {
  SelfHelpPage,
  SelfHelpSection,
} from "../../models/selfHelp/selfHelpSection";
import { RootState } from "../store";

export enum ActionButton {
  Publish = "Publish",
  PublishDoNotChangePublisher = "PublishDoNotChangePublisher",
  DiscardChanges = "DiscardChanges",
  DiscardDraft = "DiscardDraft",
  SaveAsDraft = "SaveAsDraft",
  ShareUrl = "ShareUrl",
  Create = "Create",
  SetState = "SetState",
  HardDelete = "HardDelete",
  GoToLatest = "GoToLatest",
}

export interface SelfHelpState {
  current: CurrentSelfHelp;
  uncompletedSections: SelfHelpSection[];
  visibleActionButtons: ActionButton[];
  hasDetailsUncompletedSections: boolean;
}

const initialState: SelfHelpState = {
  current: CurrentSelfHelp.None,
  visibleActionButtons: [],
  uncompletedSections: [],
  hasDetailsUncompletedSections: true,
};

const checkHasDetailsUncompletedSections = (
  allUncompleted: SelfHelpSection[]
) => {
  const detailsRequiredSections = allUncompleted.filter(
    (x) => x.page === SelfHelpPage.Details && !x.optional
  );
  return detailsRequiredSections.length > 0;
};

export const selfHelpSlice = createSlice({
  name: "selfHelp",
  initialState,
  reducers: {
    setCurrentSelfHelp(
      state: SelfHelpState,
      action: PayloadAction<CurrentSelfHelp>
    ) {
      state.current = action.payload;
    },
    addVisibleButton(
      state: SelfHelpState,
      action: PayloadAction<ActionButton>
    ) {
      state.visibleActionButtons = [
        ...state.visibleActionButtons,
        action.payload,
      ];
    },
    removeVisibleButton(
      state: SelfHelpState,
      action: PayloadAction<ActionButton>
    ) {
      remove(state.visibleActionButtons, (button) => {
        return button === action.payload;
      });
    },
    setUncompletedSections(
      state: SelfHelpState,
      action: PayloadAction<SelfHelpSection[]>
    ) {
      state.uncompletedSections = action.payload;
      state.hasDetailsUncompletedSections = checkHasDetailsUncompletedSections(
        action.payload
      );
    },
  },
});

export const {
  setCurrentSelfHelp,
  addVisibleButton,
  removeVisibleButton,
  setUncompletedSections,
} = selfHelpSlice.actions;

export const selectCurrentSelfHelp = (state: RootState) =>
  state.selfHelp.current;

export const selectVisibleActionButtons = (state: RootState) =>
  state.selfHelp.visibleActionButtons;

export const selectUncompletedSections = (state: RootState) =>
  state.selfHelp.uncompletedSections;

export const selectDetailsHasRequiredUncompletedSections = (state: RootState) =>
  state.selfHelp.hasDetailsUncompletedSections;

export default selfHelpSlice.reducer;
