import {
  selectAvailableTagsByType,
  selectCurrentDocumentTagsByType,
  setEditedTags,
} from "../../../../app/slices/documentMetadataSlice";
import { DetailsAutoComplete } from "../../inputs/DetailsAutoComplete";
import Tag from "../../../../models/tag";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { useCallback, useEffect, useMemo } from "react";
import { ComboBoxListItem } from "../../../../models/autocompleteValue";
import { EditableState } from "../../../../app/hooks/permissions/models/state";
import { find, includes, sortBy } from "lodash";
import {
  addTagWithValidationWarning,
  removeTagWithValidationWarning,
} from "../../../../app/slices/documentMetadataValidationSlice";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";

interface TagControlProps {
  tag: Tag;
  isLoading: boolean;
  isError: boolean;
  editableState: EditableState;
  handleRefreshView: () => void;
}

export function TagControl(props: TagControlProps) {
  const dispatch = useAppDispatch();

  const { tagSection } = useDocumentEditedMode();

  const currentDocumentTags = useAppSelector((state) =>
    selectCurrentDocumentTagsByType(state, props.tag.name)
  );

  const availableTags = useAppSelector((state) =>
    selectAvailableTagsByType(state, props.tag.name)
  );

  const items = useMemo(() => {
    return availableTags.map((tag) => {
      return {
        key: tag.key + tag.value,
        value: tag.value,
      };
    });
  }, [availableTags]);

  const selected = useMemo(() => {
    const items = currentDocumentTags.map((tag) => {
      const atValues = availableTags.map((at) => at.value);

      return {
        key: tag.key + tag.value,
        value: tag.value,
        isEdited: includes(tagSection.editedTags, tag),
        isMissing: !includes(atValues, tag.value),
      };
    });

    return sortBy(items, ["isEdited", "value"]);
  }, [availableTags, currentDocumentTags, tagSection]);

  const isWarning = useMemo(() => {
    return availableTags.length === 0;
  }, [availableTags]);

  const isMissingValueWarning = useMemo(() => {
    return (
      find(selected, (item) => {
        return item.isMissing;
      }) !== undefined
    );
  }, [selected]);

  const isEdited = useMemo(() => {
    return tagSection.tagSectionIsEdited;
  }, [tagSection]);

  useEffect(() => {
    if (isWarning || isMissingValueWarning) {
      dispatch(addTagWithValidationWarning(props.tag.name));
    } else {
      dispatch(removeTagWithValidationWarning(props.tag.name));
    }
  }, [dispatch, isMissingValueWarning, isWarning, props.tag.name]);

  useEffect(() => {
    return () => {
      dispatch(removeTagWithValidationWarning(props.tag.name));
    };
  }, [dispatch, props.tag.name]);

  const changeHandler = useCallback(
    (values: ComboBoxListItem[]) => {
      dispatch(
        setEditedTags({
          newValues: values,
          tagKey: props.tag.name,
        })
      );
    },
    [dispatch, props.tag.name]
  );

  return (
    <DetailsAutoComplete
      key={props.tag.name}
      id={props.tag.name}
      isEditable={props.editableState.isEditable}
      labelText={props.tag.displayName}
      omitDataQualityCheck={isWarning}
      isDisabled={!props.editableState.isEditable}
      onRefreshHandler={props.handleRefreshView}
      isDictionaryDataFetchingError={props.isError}
      isLoading={props.isLoading}
      selected={selected}
      items={items}
      notEditableMessage={props.editableState.lockMessage}
      multiple={true}
      pillsClassName={props.tag.name + "-type-pill"}
      onChangeHandler={changeHandler}
      isEdited={isEdited}
      missingValueWarningText="Highlighted tag values are not available anymore"
      isWarning={isWarning}
      warningMessage={
        "This tag type is not allowed for selected Main Category. It will be removed from document if it is saved or published."
      }
    />
  );
}
