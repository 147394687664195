import { ComboBoxListItem } from "../../../../../models/autocompleteValue";
import Tag from "../../../../../models/tag";
import { DetailsAutoComplete } from "../../../inputs/DetailsAutoComplete";

interface AutoCompleteControlProps {
  selectedTag: Tag | undefined;
  availableTags: Tag[];
  isLoading: boolean;
  onChangeHandler: (items: ComboBoxListItem[]) => void;
}

export function MultiValueTagControl(props: AutoCompleteControlProps) {
  const items = props.availableTags
    .sort((a, b) =>
      a.displayName.toLocaleLowerCase() >= b.displayName.toLocaleLowerCase()
        ? 1
        : -1
    )
    .map<ComboBoxListItem>((tag) => ({
      key: tag.name,
      value: tag.displayName,
    }));

  const selectedTag = props.selectedTag
    ? [
        {
          key: props.selectedTag.name,
          value: props.selectedTag.displayName,
        },
      ]
    : [];

  return (
    <DetailsAutoComplete
      id="tag-types-select"
      labelText="Please select Tag type from dropdown list"
      isEditable={true}
      selected={selectedTag}
      items={items}
      isLoading={props.isLoading}
      onChangeHandler={props.onChangeHandler}
      isEdited={false}
      multiple={false}
      omitDataQualityCheck={true}
      alwaysShowBorder={true}
    />
  );
}
