import { createApi } from "@reduxjs/toolkit/query/react";
import { CategoryTag } from "../models/categoryTag";
import Tag from "../models/tag";
import { publishApiBaseQuery } from "./baseQueries";

export const tagsApi = createApi({
  reducerPath: "tagsApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    getTagsList: builder.query<Tag[], string[]>({
      query: (tagNames: string[]) => ({
        url: `Tags`,
        method: "POST",
        body: tagNames,
      }),
      keepUnusedDataFor: 3600,
    }),
    getTagsByCategoryId: builder.query<CategoryTag[], string>({
      query: (categoryId: string) => ({
        url: `Tags/${categoryId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 3600,
    }),
  }),
});

export const {
  useGetTagsListQuery,
  useLazyGetTagsListQuery,
  useGetTagsByCategoryIdQuery,
  useLazyGetTagsByCategoryIdQuery,
} = tagsApi;
export default tagsApi.reducer;
