import { ReactNode, useState } from "react";
import { UncontrolledTextFieldInput } from "./UncontrolledTextFieldInput";

interface TextFieldInputProps {
  id: string;
  initialValue?: string;
  label: string;
  placeholder?: string;
  validationRegExp?: RegExp;
  validationMsg?: string;
  trimEntries?: boolean;
  onClick: (text: string) => void;
  icon?: ReactNode;
  iconText?: string;
  autoFocus?: boolean;
}

export function TextFieldInput(props: TextFieldInputProps) {
  const [inputValue, setInputValue] = useState(props.initialValue ?? "");

  const handleChange = (text: string) => {
    setInputValue(text);
  };

  const handleSearchClick = () => {
    const value =
      props.trimEntries === undefined || props.trimEntries
        ? inputValue.trim()
        : inputValue;
    props.onClick(value);
  };

  const handleClearPress = () => {
    setInputValue("");
    props.onClick("");
  };

  return (
    <UncontrolledTextFieldInput
      {...props}
      onChange={handleChange}
      onClick={handleSearchClick}
      onClear={handleClearPress}
      value={inputValue}
    />
  );
}
