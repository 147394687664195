import { HTMLAttributes, useState } from "react";
import { ComboBoxListItem } from "../../../models/autocompleteValue";
import { InputDiv } from "../../../controls/StyledComponents/InputDiv";
import { SectionFieldDiv } from "./SC/SectionFieldDiv";
import { AutoCompleteInput } from "../../../controls/Autocomplete/AutoCompleteInput";
import { AutoCompleteLabelSpan as AutoCompleteLabelSpan } from "./SC/AutoCompleteLabelSpan";
import { AutocompleteRenderOptionState, Box, Skeleton } from "@mui/material";
import { LabelContainerDiv } from "./SC/LabelContainerDiv";
import LabelIcon from "./LabelIcon";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { Editable } from "../sections/common/Editable";
import { ListItemSpan } from "../../../controls/Autocomplete/SC/ListItemSpan";
import { StyledWarningIcon } from "../../../controls/StyledComponents/StyledWarningIcon";
import { TooltipTop } from "../../../controls/Tooltips/ToolipTop";

interface AutoCompleteProps extends Editable {
  isLoading?: boolean;
  id: string;
  labelText: string;
  selected: ComboBoxListItem[];
  items: ComboBoxListItem[];
  isDisabled?: boolean;
  isDictionaryDataFetchingError?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  warningMessage?: string;
  infoIcon?: React.ReactNode;
  onInfoIconClick?: (event: React.MouseEvent) => void;
  $paddingLeft?: number;
  pillsClassName?: string;
  multiple: boolean;
  onRefreshHandler?: () => void;
  onChangeHandler: (values: ComboBoxListItem[]) => void;
  isHidden?: boolean;
  isEdited: boolean;
  inputStartAdornment?: React.ReactNode;
  inputEndAdornment?: React.ReactNode;
  notEditableMessage?: TooltipMsg;
  omitDataQualityCheck?: boolean;
  errorMessages?: string[];
  missingValueWarningText?: string;
  renderOption?:
  | ((
    props: React.HTMLAttributes<HTMLLIElement>,
    option: unknown,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode)
  | undefined;
  alwaysShowBorder?: boolean;
  noOptionText?: string;
}

export function DetailsAutoComplete(props: AutoCompleteProps) {
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
    setUserCanEdit(props.isEditable);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
    if (!isMenuOpen) {
      setUserCanEdit(false);
    }
  };

  const handleOpen = () => setIsMenuOpen(true);

  const handleClose = () => {
    setIsMenuOpen(false);
    if (!isMouseOver) {
      setUserCanEdit(false);
    }
  };

  const getMenuItem = (
    props: HTMLAttributes<HTMLLIElement>,
    option: unknown
  ) => {
    const item = option as ComboBoxListItem;
    return (
      <Box component="li" {...props}>
        <ListItemSpan>
          {item.hasDataQualityWarning && (
            <TooltipTop title={TooltipMsg.AutoCompleteQualityIssue}>
              <StyledWarningIcon margin={2} fontSize="inherit" />
            </TooltipTop>
          )}
          {item.value}
        </ListItemSpan>
      </Box>
    );
  };

  const getAutoCompleteInput = () => {
    return (
      <InputDiv>
        <AutoCompleteInput
          id={props.id + "-autocomplete"}
          isEditable={userCanEdit || !!props.alwaysShowBorder}
          items={props.items}
          isDictionaryDataFetchingError={props.isDictionaryDataFetchingError}
          selected={props.selected}
          $paddingLeft={props.$paddingLeft}
          multiple={props.multiple}
          onChangeHandler={props.onChangeHandler}
          isLoading={props.isLoading === true}
          isError={props.isError}
          isWarning={props.isWarning}
          warningMessage={props.warningMessage}
          missingValueWarningText={props.missingValueWarningText}
          onRefreshHandler={props.onRefreshHandler}
          onClose={handleClose}
          onOpen={handleOpen}
          renderOption={props.renderOption ? props.renderOption : getMenuItem}
          pillsClassName={props.pillsClassName ? props.pillsClassName : ""}
          isEdited={props.isEdited}
          inputEndAdornment={props.inputEndAdornment}
          inputStartAdornment={props.inputStartAdornment}
          isDisabled={props.isDisabled}
          omitDataQualityCheck={props.omitDataQualityCheck}
          errorMessages={props.errorMessages}
          noOptionsText={props.noOptionText}
        />
      </InputDiv>
    );
  };

  return (
    <SectionFieldDiv
      id={props.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      $isHidden={props.isHidden}
    >
      <LabelContainerDiv>
        {props.labelText && (
          <AutoCompleteLabelSpan
            onClick={props.onInfoIconClick}
            clickable={props.onInfoIconClick !== undefined}
          >
            {props.labelText}
            {props.infoIcon !== undefined ? props.infoIcon : ":"}
          </AutoCompleteLabelSpan>
        )}
        {props.notEditableMessage && !props.isEditable && (
          <LabelIcon message={props.notEditableMessage} />
        )}
      </LabelContainerDiv>
      {props.isLoading ? (
        <Skeleton width={"auto"}>{getAutoCompleteInput()}</Skeleton>
      ) : (
        getAutoCompleteInput()
      )}
    </SectionFieldDiv>
  );
}
