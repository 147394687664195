/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { CircularProgress } from "@mui/material";
import { sortBy } from "lodash";
import { useTheme } from "styled-components";
import { PaperControl } from "../../../controls/Paper/PaperControl";
import { PaperContentDiv } from "../../../controls/Paper/SC/PaperContentDiv";
import { Person } from "../../../models/person";
import { GroupsColumnDiv } from "./SC/GroupsColumnDiv";
import { GroupsList } from "./SC/GroupsList";
import { NoOwnersFound } from "./NoOwnersFound";
import { DenseItemAvatar } from "./DenseItemAvatar";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { ErrorDisplay } from "../../../app/errorHandling/ErrorDisplay";
import { PaperContentCenterDiv } from "./SC/PaperContentCenterDiv";

const fetchAdmins = () => {
  console.log("fetchAdmins");
};

const isAdminsFetching = false;
const isAdminsError = false;
const users: Person[] = [
  {
    email: "grzegorz.liszka@pl.abb.com",
    name: "Grzegorz Liszka",
  },
  {
    email: "grzegorz.liszka2@pl.abb.com",
    name: "Grzegorz Liszka2",
  },
]

export function TagOwnersGroupColumn() {
  const theme = useTheme();

  if (isAdminsError) {
    return (
      <GroupsColumnDiv id="admins-column" width={100} height={50} >
        <PaperControl title={"Owners"} currentSelfHelp={CurrentSelfHelp.Tags} selfHelpPage={SelfHelpPage.Tags} selfHelpSectionName={SectionName.Tags} useHeader={true}>
          <PaperContentCenterDiv>
            <ErrorDisplay
              errorId={"members"}
              errorMessageTitle={"Something went wrong"}
              errorDescription={"Error while fetching access group admins."}
              refreshFunction={fetchAdmins}
              showReloadButton={true}
            />
          </PaperContentCenterDiv>
        </PaperControl>
      </GroupsColumnDiv>
    );
  }

  const content = () => {
    if (isAdminsFetching) {
      return (
        <PaperContentCenterDiv>
          <CircularProgress
            sx={{ color: theme.palettes.primary.main }}
            size="30px"
          />
        </PaperContentCenterDiv>
      );
    }

    if (users && users.length > 0) {
      return (
        <PaperContentDiv id="admins-content-div">
          <GroupsList id={"list"} sx={{
            // hover states
            '& .MuiListItem-root:hover': {
              bgcolor: theme.palettes.grayscale.lighter,
            },
          }} dense={true}>
            {sortBy(users, ["email"]).map((p) => {
              return (
                <DenseItemAvatar
                  origin="admins"
                  id={
                    p.email !== null
                      ? "admin-li-item-" + p.email
                      : "admin-person-li-item"
                  }
                  key={p.email}
                  primaryInfo={p.name ? p.name : ""}
                  secondaryInfo={p.email ? p.email : ""}
                  type={"person"}
                  isBold={false}
                />
              );
            })}
          </GroupsList>
        </PaperContentDiv>
      );
    }

    return (
      <PaperContentCenterDiv id="admins-no-content-div">
        <NoOwnersFound />
      </PaperContentCenterDiv>
    );
  };

  return (
    <GroupsColumnDiv id="admins-column" width={100} height={50}   >
      <PaperControl title={"Owners"} currentSelfHelp={CurrentSelfHelp.Tags} selfHelpPage={SelfHelpPage.Tags} selfHelpSectionName={SectionName.Tags} useHeader={true}>
        {content()}
      </PaperControl>
    </GroupsColumnDiv>
  );
}
