import { IconButtonControl } from "./IconButtonControl";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import {
  selectUncompletedSections,
  setCurrentSelfHelp,
} from "../../app/slices/selfHelpSlice";
import { CurrentSelfHelp } from "../../models/CurrentSelfHelpType";
import { SectionName } from "../../app/slices/models/documentDetailsModels";
import {
  SectionState,
  SelfHelpPage,
} from "../../models/selfHelp/selfHelpSection";
import { StyledHelpIcon } from "../../components/details/sections/SectionHeader/controls/SC/StyledHelpIcon";
import { TooltipMsg } from "../Tooltips/TooltipMessages";

interface SelfHelpButtonControlProps {
  currentSelfHelp?: CurrentSelfHelp;
  section: SectionName;
  page: SelfHelpPage;
  disabled?: boolean;
  id?: string;
  onClickHandler?: (e: React.SyntheticEvent) => void;
}

export function SelfHelpButtonControl(props: SelfHelpButtonControlProps) {
  const dispatch = useAppDispatch();
  const uncompletedSections = useAppSelector(selectUncompletedSections);

  const getSelfHelpColor = () => {
    const section = uncompletedSections.find(
      (u) => u.sectionName === props.section && u.page === props.page
    );

    if (!section) return SectionState.Completed;
    if (section.optional) return SectionState.Optional;

    return SectionState.Mandatory;
  };

  const getSelfHelpTooltip = () => {
    const section = uncompletedSections.find(
      (u) => u.sectionName === props.section && u.page === props.page
    );

    if (!section) return TooltipMsg.SelfHelpCompleted;
    if (section.optional) return TooltipMsg.SelfHelpOptional;

    return TooltipMsg.SelfHelpMandatory;
  };

  const handleSelfHelpClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (props.currentSelfHelp) {
      dispatch(setCurrentSelfHelp(props.currentSelfHelp));
    }
    if (props.onClickHandler) {
      props.onClickHandler(event);
    }
  };

  return (
    <IconButtonControl
      id={props.id}
      disabled={props.disabled}
      onClick={handleSelfHelpClick}
      popupText={getSelfHelpTooltip()}
      icon={<StyledHelpIcon $colorNew={getSelfHelpColor()} />}
    />
  );
}
