import { Avatar } from "@mui/material";

import { StyledListItem } from "./SC/StyledListItem";
import { StyledListItemAvatar } from "./SC/StyledListItemAvatar";
import { StyledListItemText } from "./SC/StyledListItemText";
import { StyledFolderIcon } from "./SC/StyledFolderIcon";
import { StyledPersonIcon } from "./SC/StyledPersonIcon";


interface ItemAvatarProps {
  id: string;
  origin: string;
  primaryInfo: string;
  secondaryInfo: string;
  type: "person" | "group";
  isBold: boolean;
}

export function DenseItemAvatar(props: ItemAvatarProps) {

  return (
    <StyledListItem
      id={props.id}
      className={props.origin + "-dense-item-row"}
    >
      <StyledListItemAvatar>
        <Avatar sx={{ width: 28, height: 28 }}>
          {props.type === "group" && (
            <StyledFolderIcon fontSize="small" />
          )}
          {props.type === "person" && (
            <StyledPersonIcon fontSize="small" />
          )}
        </Avatar>
      </StyledListItemAvatar>
      <StyledListItemText
        primary={props.primaryInfo}
        secondary={props.secondaryInfo}
        $isBold={props.isBold}
      />
    </StyledListItem>
  );
}
