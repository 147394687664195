import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { PaperControl } from "../../../controls/Paper/PaperControl";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { TagsInfoDiv } from "../SC/TagsInfoDiv";
import { TagValuesDiv } from "../SC/TagValuesDiv";
import { AddTagValue } from "./AddValue/AddTagValue";
import { TagValueInfo } from "./TabValueInfo";


export function TagValues() {
    return (
        <PaperControl useHeader={true} title="Tag Values"
            currentSelfHelp={CurrentSelfHelp.Tags}
            selfHelpPage={SelfHelpPage.Tags}
            selfHelpSectionName={SectionName.Tags}
        >
            <TagValuesDiv>
                <TagsInfoDiv>
                    <AddTagValue />
                    <TagValuesDiv>
                        <TagValueInfo name="Some tag value" />
                        <TagValueInfo name="Some tag value" />
                        <TagValueInfo name="Some tag value" />
                        <TagValueInfo name="Some tag value" />
                        <TagValueInfo name="Some tag value" />
                        <TagValueInfo name="Some tag value" />
                    </TagValuesDiv>
                </TagsInfoDiv>
            </TagValuesDiv>
        </PaperControl>
    );
}
