import { useAppSelector } from "../../../app/hooks/hooks";
import { selectIsWindowTooSmall } from "../../../app/slices/commonSlice";
import { CreateButton } from "./buttons/CreateButton";
import { GoToLatestRevisionButton } from "./buttons/GoToLatestRevisionButton";
import { SetStateButton } from "./buttons/SetStateButton";
import { ShareUrlButton } from "./buttons/ShareURLButton";
import { noEditorRightsText } from "./helpers/buttonsHelper";
import { selectIsChangingWorkflow } from "../../../app/slices/documentDetailsSlice";
import { selectDocumentStatus, selectDocumentWorkflow, selectHasOtherRevisionWithPendingApproval } from "../../../app/slices/documentMetadataSlice";
import { PermissionsEditableState } from "../../../app/hooks/permissions/useUserPermissionsInDocument";

interface PublishedDocumentButtonsProps {
    hasPermissionToEditDocument: PermissionsEditableState;
}

export function PublishedDocumentButtons(props: PublishedDocumentButtonsProps) {
    const isWindowToSmall = useAppSelector(selectIsWindowTooSmall);
    const documentStatus = useAppSelector(selectDocumentStatus);
    const isChangingWorkflow = useAppSelector(selectIsChangingWorkflow);
    const hasOtherRevisionWithPendingApproval = useAppSelector(
        selectHasOtherRevisionWithPendingApproval
    );
    const workflow = useAppSelector(selectDocumentWorkflow);

    return <>
        <CreateButton
            disabled={!props.hasPermissionToEditDocument.isEditable}
            disabledTooltipText={noEditorRightsText}
            isCompactView={isWindowToSmall}
            hasPendingApprovals={hasOtherRevisionWithPendingApproval}
        />
        <ShareUrlButton isCompactView={isWindowToSmall} />
        <SetStateButton
            disabled={isChangingWorkflow}
            workflow={workflow}
            isCompactView={isWindowToSmall}
        />
        {!documentStatus.isLatest && (
            <GoToLatestRevisionButton
                latestRevision={documentStatus.latestRevision}
                isCompactView={isWindowToSmall}
            />)}
    </>
}