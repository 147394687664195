import { ListItemText } from "@mui/material";
import styled from "styled-components";

interface StyledListItemTextProps {
  $isBold?: boolean;
  $isBlackColor?: boolean;
  $hasPointer?: boolean;
}

export const StyledListItemText = styled(ListItemText) <StyledListItemTextProps>`
  &.MuiListItemText-root {
    margin-top: 0px;
    margin-bottom: 0px;
    align-items: center;
    justify-content: center;
    color: ${(props) =>
    props.$isBlackColor
      ? props.theme.palettes.primary.dark
      : props.theme.palettes.grayscale.medium};
    cursor: ${(props) => (props.$hasPointer ? "pointer" : "cursor")};
  }

  .MuiListItemText-primary {
    font-weight: ${(props) => (props.$isBold ? "bold" : "normal")};
  }
`;
