import { Divider } from "@mui/material";
import { Footer } from "../footer/Footer";
import { TagsHeader } from "./Header/TagsHeader";
import { TagsDiv } from "./SC/TagsDiv";
import { useMemo, useCallback, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks/hooks";
import { setTagsViewInLocalStorage } from "../../app/hooks/localStorage";
import useWindowDimensions from "../../app/hooks/useWindowDimensions";
import { selectIsWindowTooSmall } from "../../app/slices/commonSlice";
import { navigationMinWidth } from "../../app/slices/models/contentModels";
import { selectIsNavigationMinimized } from "../../app/slices/navigationSlice";
import { HandlerProps, ReflexContainer, ReflexElement, ReflexSplitter } from "../../controls/Reflex";
import { selectDashboardFlex, selectNavigationFlex, setDashboardFlex, setNavigationFlex } from "../../app/slices/tagsViewSlice";
import { TagTypes } from "./TagTypes/TagTypes";
import { TagsDashboard } from "./TagsDashboard/TagsDashboard";

const tagsMinWidth = 680;
const hiddenTagsWidth = 0;
const tagsMinimizedWidth = 20;

const clamp = (value: number) => +Math.max(0, Math.min(1, value)).toFixed(2);

export function Tags() {
  const navigationFlex = useAppSelector(selectNavigationFlex);
  const dashboardFlex = useAppSelector(selectDashboardFlex);
  const isMinimizedByUser = useAppSelector(selectIsNavigationMinimized);
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);
  const { windowWidth } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const maxNavigationWidth = useMemo(
    () => windowWidth - tagsMinWidth,
    [windowWidth]
  );
  const isMinimized = useMemo(
    () => isMinimizedByUser || isWindowTooSmall,
    [isMinimizedByUser, isWindowTooSmall]
  );

  const onNavigationStopResize = useCallback(
    (args: HandlerProps) => {
      if (!args.component.props.flex) {
        return;
      }

      const el = args.domElement as Element;
      const navFlex = clamp(
        el.clientWidth > navigationMinWidth
          ? args.component.props.flex
          : navigationMinWidth / windowWidth
      );
      const pubFlex = 1 - navFlex;

      dispatch(setNavigationFlex(navFlex));
      dispatch(setDashboardFlex(pubFlex));
    },
    [windowWidth, dispatch]
  );

  useEffect(() => {
    const dashboardFlex =
      (windowWidth -
        (isMinimized
          ? tagsMinimizedWidth
          : navigationFlex * windowWidth)) /
      windowWidth;
    let clampedPubFlex = clamp(dashboardFlex);
    let navFlex = 1 - clampedPubFlex;

    if (!isMinimized) {
      const navWidth = navFlex * windowWidth;

      if (navWidth < navigationMinWidth) {
        navFlex = Math.max(navFlex, clamp(navigationMinWidth / windowWidth));
        clampedPubFlex = clamp(1 - navFlex);
      }
    }

    dispatch(setDashboardFlex(clampedPubFlex));
    dispatch(setNavigationFlex(navFlex));
  }, [navigationFlex, windowWidth, isMinimized, dispatch]);

  useEffect(() => {
    setTagsViewInLocalStorage({
      navigationFlex: navigationFlex,
      dashboardFlex: dashboardFlex,
      isMinimized: isMinimized,
    });
  }, [navigationFlex, dashboardFlex, isMinimized]);


  return (
    <TagsDiv id="tags-div">
      <TagsHeader />
      <Divider
        variant="fullWidth"
        sx={{ width: "100%" }}
      />
      <ReflexContainer orientation="vertical" windowResizeAware={true}>
        <ReflexElement
          flex={isMinimized ? hiddenTagsWidth : navigationFlex}
          onStopResize={onNavigationStopResize}
          className="left-pane"
          minSize={isMinimized ? tagsMinimizedWidth : navigationMinWidth}
          maxSize={isMinimized ? tagsMinimizedWidth : maxNavigationWidth}
        >
          <TagTypes />
        </ReflexElement>
        <ReflexSplitter />
        <ReflexElement
          flex={dashboardFlex}
          minSize={tagsMinWidth}
          className="center-pane"
        >
          <TagsDashboard />
        </ReflexElement>
      </ReflexContainer>

      <Footer />
    </TagsDiv>
  );
}
