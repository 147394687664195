import { Skeleton } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetAppSettingsQuery } from "../../../apis/configApi";
import { getWorkflowName } from "../../../app/helpers/getTooltips";
import { useDocumentEditedMode } from "../../../app/hooks/document/useDocumentEditedMode";
import { useDocumentCategoriesState } from "../../../app/hooks/document/useDocumentCategoriesState";
import { useDocumentState } from "../../../app/hooks/document/useDocumentState";
import { useAppSelector } from "../../../app/hooks/hooks";
import { initialEditableState } from "../../../app/hooks/permissions/models/state";
import { useUserCanEditDocument } from "../../../app/hooks/permissions/useUserCanEditDocument";
import { useUserPermissionsInCategory } from "../../../app/hooks/permissions/useUserPermissionsInCategory";
import useWindowDimensions from "../../../app/hooks/useWindowDimensions";
import {
  selectIsLoadingDetailsMetaData,
  selectTabIndex,
} from "../../../app/slices/documentDetailsSlice";
import {
  selectCurrentDocumentMetadata,
  selectDocumentStatus,
  selectIsApprovalMainCategory,
} from "../../../app/slices/documentMetadataSlice";
import { TooltipTop } from "../../../controls/Tooltips/ToolipTop";
import { WordBreakType } from "../../../models/documentList/types/WordBreakType";
import { Workflow } from "../../../models/documentList/types/workflow";
import { EllipsisText } from "../../documents/documentsList/documentListItem/cells/EllipsisText";
import { LabelSpan } from "../SC/LabelSpan";
import { TextSpan } from "../SC/TextSpan";
import { TooltipMsg } from "../../../controls/Tooltips/TooltipMessages";
import { DocumentHeaderDiv } from "./SC/DocumentHeaderDiv";
import { DocumentHeaderLinkIcon } from "./SC/DocumentHeaderLinkIcon";
import { DocumentHeaderLockIcon } from "./SC/DocumentHeaderLockIcon";
import { DocumentHeaderServiceDocumentIcon } from "./SC/DocumentHeaderServiceDocumentIcon";
import { DocumentHeaderTabs } from "./SC/DocumentHeaderTabs";
import { HeaderFieldDiv } from "./SC/HeaderFieldDiv";
import { HeaderFieldGroupDiv } from "./SC/HeaderFieldGroupDiv";
import { HeaderFirstRowDiv } from "./SC/HeaderFirstRowDiv";
import { HeaderSecondRowDiv } from "./SC/HeaderSecondRowDiv";
import { TitleDiv } from "./SC/TitleDiv";
import { TitleSpan } from "./SC/TitleSpan";
import { WorkflowFieldDiv } from "./SC/WorkflowFieldDiv";
import { useUserPermissionsInDocument } from "../../../app/hooks/permissions/useUserPermissionsInDocument";
import { selectUserName } from "../../../app/slices/authenticationSlice";
import { useDocumentFlow } from "../../../app/hooks/document/useDocumentFlow";
import { DocumentHeaderDeletedIcon } from "./SC/DocumentHeaderDeletedIcon";
import { EditModeSpan } from "./SC/EditModeSpan";

function getPublisher(workflow: Workflow, publisher: string) {
  return (
    <TextSpan id="document-metadata-header-publisher">
      {workflow == Workflow.Draft ||
      workflow == Workflow.NewDocument ||
      workflow == Workflow.NewRevision
        ? "[" + publisher + "]"
        : publisher}
    </TextSpan>
  );
}

function getPublishedDate(workflow: Workflow, publishedDate: string) {
  return (
    <TextSpan id="document-metadata-header-publishedDate">
      {workflow == Workflow.Draft ||
      workflow == Workflow.NewDocument ||
      workflow == Workflow.NewRevision
        ? "[" + publishedDate + "]"
        : publishedDate}
    </TextSpan>
  );
}

const pageMargins = 80;
const svgWidth = 25;

export function DocumentHeader() {
  const [tabsWidth, setTabsWidth] = useState(0);
  const tabsRef = useRef<HTMLDivElement>(null);
  const publishGroupRef = useRef<HTMLDivElement>(null);
  const documentLabelRef = useRef<HTMLLabelElement>(null);
  const documentHeaderRef = useRef<HTMLDivElement>(null);
  const tabIndex = useAppSelector(selectTabIndex);
  const { data: config } = useGetAppSettingsQuery(null);
  const dateFormat = config ? config.localization.dateFormat : "";
  const { windowWidth } = useWindowDimensions();
  const documentMetadata = useAppSelector(selectCurrentDocumentMetadata);
  const documentStatus = useAppSelector(selectDocumentStatus);
  const { isInEditMode } = useDocumentEditedMode();
  const isLoadingDetailsMetaData = useAppSelector(
    selectIsLoadingDetailsMetaData
  );
  const isApprovalCategory = useAppSelector(selectIsApprovalMainCategory);
  const [editableState, setEditableState] = useState(initialEditableState);
  const { canEditDocument } = useUserCanEditDocument();
  const userName = useAppSelector(selectUserName);

  const { isEditBlockedForPendingApproval, isEditBlockedForApproval } =
    useUserPermissionsInDocument(isApprovalCategory);
  const { hasAccessToAnyDocumentCategory } =
    useUserPermissionsInCategory(undefined);
  useEffect(() => {
    setEditableState(canEditDocument(false));
  }, [canEditDocument]);

  const { isDocumentFromServiceIntegration } = useDocumentState();
  const { isDeletedDocumentView, isNewDocumentVersion } = useDocumentFlow();
  const { categoriesWereModified } = useDocumentCategoriesState();

  const getIcon = useCallback(() => {
    if (!isDeletedDocumentView && isDocumentFromServiceIntegration) {
      return (
        <TooltipTop title={TooltipMsg.DocumentHeaderServiceIntegration}>
          <DocumentHeaderServiceDocumentIcon
            fontSize="inherit"
            id="document-metadata-header-lockIcon"
          />
        </TooltipTop>
      );
    }

    if (
      !editableState.isEditable &&
      ((!hasAccessToAnyDocumentCategory && !categoriesWereModified) ||
        isEditBlockedForPendingApproval ||
        isEditBlockedForApproval)
    ) {
      return (
        <>
          <TooltipTop
            title={
              isEditBlockedForApproval || isEditBlockedForPendingApproval
                ? TooltipMsg.CannotApprovePendingApproval
                : TooltipMsg.DocumentHeaderNoAccess
            }
          >
            <DocumentHeaderLockIcon
              fontSize="inherit"
              id="document-metadata-header-lockIcon"
            />
          </TooltipTop>
          {isDeletedDocumentView && (
            <TooltipTop title={TooltipMsg.DocumentHeaderDeleted}>
              <DocumentHeaderDeletedIcon
                fontSize="inherit"
                id="document-metadata-header-deleteIcon"
              />
            </TooltipTop>
          )}
        </>
      );
    }

    if (
      !isDeletedDocumentView &&
      !editableState.isEditable &&
      (hasAccessToAnyDocumentCategory || categoriesWereModified)
    )
      return (
        <TooltipTop title={TooltipMsg.LinkedDocument}>
          <DocumentHeaderLinkIcon
            fontSize="inherit"
            id="document-metadata-header-linkIcon"
          />
        </TooltipTop>
      );

    if (isDeletedDocumentView && hasAccessToAnyDocumentCategory)
      return (
        <TooltipTop title={TooltipMsg.DocumentHeaderDeleted}>
          <DocumentHeaderDeletedIcon
            fontSize="inherit"
            id="document-metadata-header-deleteIcon"
          />
        </TooltipTop>
      );
  }, [
    isDeletedDocumentView,
    editableState.isEditable,
    hasAccessToAnyDocumentCategory,
    isDocumentFromServiceIntegration,
    isEditBlockedForApproval,
    isEditBlockedForPendingApproval,
    categoriesWereModified,
  ]);

  useEffect(() => {
    if (tabsRef.current?.clientWidth) setTabsWidth(tabsRef.current.clientWidth);
  }, []);

  const title =
    documentMetadata.titles.find((title) => {
      return title.languageIsoCode === "en";
    })?.title ?? "";

  const getTitleWidth = () => {
    return windowWidth - tabsWidth - pageMargins;
  };

  const getDocumentNumberWidth = () => {
    return (
      (documentHeaderRef.current?.getBoundingClientRect().width ?? 0) -
      (publishGroupRef.current?.getBoundingClientRect().width ?? 0) -
      (documentLabelRef.current?.getBoundingClientRect().width ?? 0)
    );
  };

  return (
    <DocumentHeaderDiv ref={documentHeaderRef} id="document-header-div">
      <HeaderFirstRowDiv id="document-header-div-first-row">
        <HeaderFieldGroupDiv>
          <HeaderFieldDiv id="document-metadata-header-documentnumber-header">
            <LabelSpan ref={documentLabelRef} float="left">
              Document number:
            </LabelSpan>
            {!isLoadingDetailsMetaData ? (
              <EllipsisText
                id="document-metadata-header-documentnumber"
                useTooltip={true}
                wordBreak={WordBreakType.BreakWord}
                content={documentMetadata.documentIdentity.documentNumber}
                fillIfEmpty={true}
                width={getDocumentNumberWidth()}
                enableEllipsis={true}
                $paddingLeft={5}
              />
            ) : (
              <Skeleton
                variant="text"
                width={180}
                height={23}
                sx={{ float: "right", marginLeft: "5px" }}
              />
            )}
          </HeaderFieldDiv>
          {!isLoadingDetailsMetaData ? (
            <WorkflowFieldDiv
              id="document-metadata-header-workflow"
              workflow={documentMetadata.workflow}
            >
              {getWorkflowName(documentMetadata.workflow)}{" "}
              {isInEditMode && <EditModeSpan>(EDIT MODE)</EditModeSpan>}
            </WorkflowFieldDiv>
          ) : (
            <Skeleton
              variant="text"
              sx={{ marginTop: "25px" }}
              height={23}
              width={120}
            />
          )}
        </HeaderFieldGroupDiv>
        <HeaderFieldGroupDiv ref={publishGroupRef}>
          <HeaderFieldDiv id="document-metadata-header-publisher-div">
            <LabelSpan>Publisher: </LabelSpan>
            {!isLoadingDetailsMetaData ? (
              getPublisher(
                documentMetadata.workflow,
                documentStatus.publisher == "" || isNewDocumentVersion
                  ? userName
                  : documentStatus.publisher
              )
            ) : (
              <Skeleton
                variant="text"
                width={120}
                height={23}
                sx={{ float: "right", marginLeft: "5px" }}
              />
            )}
          </HeaderFieldDiv>
          <HeaderFieldDiv id="document-metadata-header-publishedDate-div">
            <LabelSpan>Published Date: </LabelSpan>
            {!isLoadingDetailsMetaData ? (
              getPublishedDate(
                documentMetadata.workflow,
                moment(documentStatus.publishedDate).format(dateFormat)
              )
            ) : (
              <Skeleton
                variant="text"
                width={120}
                height={23}
                sx={{ float: "right", marginLeft: "5px" }}
              />
            )}
          </HeaderFieldDiv>
        </HeaderFieldGroupDiv>
      </HeaderFirstRowDiv>
      <HeaderSecondRowDiv id="document-header-div-secound-row">
        <TitleDiv>
          {!isLoadingDetailsMetaData ? (
            <TitleSpan
              width={getTitleWidth() + "px"}
              $isGray={!editableState.isEditable}
            >
              <EllipsisText
                id="document-metadata-header-documentTitle"
                useTooltip={true}
                wordBreak={WordBreakType.BreakWord}
                content={title}
                fillIfEmpty={true}
                width={
                  getTitleWidth() - (!editableState.isEditable ? svgWidth : 0)
                }
                enableEllipsis={true}
                isBolded={editableState.isEditable}
              />{" "}
              {getIcon()}
            </TitleSpan>
          ) : (
            <TitleSpan width={getTitleWidth() + "px"} $isGray={false}>
              <Skeleton
                variant="text"
                height={30}
                width={getTitleWidth()}
                sx={{ float: "right" }}
              />
            </TitleSpan>
          )}
        </TitleDiv>
        <TextSpan>
          <DocumentHeaderTabs
            ref={tabsRef}
            value={tabIndex}
            onChange={() => console.log("tab changed")}
            aria-label="wrapped label tabs example"
            TabIndicatorProps={{ style: { background: "secondary.main" } }}
            textColor="secondary"
          >
            {/* <Tab label="Details" /> */}
            {/* <Tab id="related-documents-tab" label="Related documents" />
            <Tab id="history-tab" label="History" /> */}
          </DocumentHeaderTabs>
        </TextSpan>
      </HeaderSecondRowDiv>
    </DocumentHeaderDiv>
  );
}
