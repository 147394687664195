import { createApi } from "@reduxjs/toolkit/query/react";
import { Identity } from "../models/documentDetails/documentMetadata";
import { WorkflowChangeType } from "../models/workflowChangeType";
import { publishApiBaseQuery } from "./baseQueries";

export interface PreviousRevisionState {
  previousNonDeletedRevision: string;
  canPreviousRevisionBePublished: boolean;
}

export const documentActionsApi = createApi({
  reducerPath: "documentActionsApi",
  baseQuery: publishApiBaseQuery,
  endpoints: (builder) => ({
    changeDocumentWorkflowAction: builder.mutation<
      string,
      { action: WorkflowChangeType; revisionIdentity: Identity }
    >({
      query: (data) => ({
        url: `Documents/${encodeURIComponent(
          data.revisionIdentity.documentNumber
        )}/${data.revisionIdentity.documentLanguageCodes.join(
          ""
        )}/${encodeURIComponent(
          data.revisionIdentity.documentPart
        )}/Revisions/${data.revisionIdentity.revision}/Actions`,
        method: "POST",
        body: {
          workflowChangeType: data.action,
        },
        responseHandler: "text",
      }),
    }),
    getPreviousNonDeletedRevisionState: builder.query<
      PreviousRevisionState,
      Identity
    >({
      query: (data) => ({
        url: `Documents/${encodeURIComponent(
          data.documentNumber
        )}/${data.documentLanguageCodes.join("")}/${encodeURIComponent(
          data.documentPart
        )}/Revisions/${data.revision}/Previous`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useChangeDocumentWorkflowActionMutation,
  useLazyGetPreviousNonDeletedRevisionStateQuery,
} = documentActionsApi;
export default documentActionsApi.reducer;
