import { useCallback, useEffect, useMemo, useState } from "react";
import Joyride, { ACTIONS, CallBackProps, STATUS, Step } from "react-joyride";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import useGetSelfHelpUncompletedSections from "../../app/hooks/useGetSelfHelpUncompletedSections";
import { selectCurrentSplash } from "../../app/slices/commonSlice";
import { selectSecurityTabAlternativeView } from "../../app/slices/documentDetailsSlice";
import {
  selectHasAnyDocumentGroupOnView,
  selectHasAnySingleDocumentOnView,
  selectVisibleColumnTypes,
} from "../../app/slices/documentListViewSlice";
import {
  selectCurrentDocumentCategories,
  selectCurrentDocumentPopularCategories,
  selectCurrentDocumentSecurityLevel,
} from "../../app/slices/documentMetadataSlice";
import {
  selectCurrentSelfHelp,
  selectUncompletedSections,
  selectVisibleActionButtons,
  setCurrentSelfHelp,
} from "../../app/slices/selfHelpSlice";
import { defaultTheme } from "../../app/theme";
import {
  CurrentSelfHelp,
  getSectionNameBySelfHelpName as getSectionBySelfHelpName,
} from "../../models/CurrentSelfHelpType";
import { DocumentSecurityLevel } from "../../models/documentSecurityLevel";
import { ScreenSplashEnum } from "../../models/screenSplash";
import { SelfHelpSectionIdentifier } from "../../models/selfHelp/selfHelpSection";
import { SelfHelpDiv } from "./SC/SelfHelpDiv";
import { getSelfHelpSteps } from "./helpers/selfHelpBuilder";

export function SelfHelp() {
  const dispatch = useAppDispatch();
  const currentSelfHelp = useAppSelector(selectCurrentSelfHelp);
  const actionButtons = useAppSelector(selectVisibleActionButtons);
  const currentSplashScreen = useAppSelector(selectCurrentSplash);
  const isEmailExportsButton = document.getElementById("header-action-emails-data-export") !== null;
  const hasAnySingleDocumentOnView = useAppSelector(
    selectHasAnySingleDocumentOnView
  );
  const securityTabAlternativeView = useAppSelector(
    selectSecurityTabAlternativeView
  );
  const currentDocumentSecurityLevel = useAppSelector(
    selectCurrentDocumentSecurityLevel
  );
  const hasAnyDocumentGroupOnView = useAppSelector(
    selectHasAnyDocumentGroupOnView
  );
  const visibleColumns = useAppSelector(selectVisibleColumnTypes);
  const currentDocumentPopularCategories = useAppSelector(
    selectCurrentDocumentPopularCategories
  );
  const currentDocumentCategories = useAppSelector(
    selectCurrentDocumentCategories
  );
  const [steps, setSteps] = useState<Step[]>([]);
  const [sectionToMark, setSectionToMark] =
    useState<SelfHelpSectionIdentifier>();
  const uncompletedSections = useAppSelector(selectUncompletedSections);
  const { fetchUncompletedSections, markSectionAsCompleted } =
    useGetSelfHelpUncompletedSections();

  const isAccessGroupsControlVisible = useMemo(() => {
    return (
      currentDocumentSecurityLevel === DocumentSecurityLevel.InternalLimited ||
      currentDocumentSecurityLevel ===
      DocumentSecurityLevel.ExternalLimitedAndInternalLimited ||
      currentDocumentSecurityLevel ===
      DocumentSecurityLevel.ExternalLimitedAndInternalPublic
    );
  }, [currentDocumentSecurityLevel]);

  const isAnyPopularCategoryVisible = useMemo(() => {
    return currentDocumentPopularCategories.length > 0;
  }, [currentDocumentPopularCategories]);

  const isAnyCategoryVisible = useMemo(() => {
    return currentDocumentCategories.length > 0;
  }, [currentDocumentCategories]);

  useEffect(() => {
    fetchUncompletedSections();
  }, [fetchUncompletedSections]);

  useEffect(() => {
    if (currentSelfHelp !== CurrentSelfHelp.None) {
      setSteps(
        getSelfHelpSteps(
          currentSelfHelp,
          visibleColumns,
          hasAnyDocumentGroupOnView,
          hasAnySingleDocumentOnView,
          securityTabAlternativeView,
          isAccessGroupsControlVisible,
          isAnyCategoryVisible,
          isAnyPopularCategoryVisible,
          actionButtons,
          isEmailExportsButton
        )
      );
    } else {
      setSteps([]);
    }
  }, [currentSelfHelp, hasAnyDocumentGroupOnView, hasAnySingleDocumentOnView, visibleColumns, securityTabAlternativeView, currentDocumentSecurityLevel, isAccessGroupsControlVisible, isAnyCategoryVisible, isAnyPopularCategoryVisible, actionButtons, isEmailExportsButton]);

  useEffect(() => {
    if (sectionToMark) {
      markSectionAsCompleted(sectionToMark);
      setSectionToMark(undefined);
    }
  }, [
    dispatch,
    sectionToMark,
    markSectionAsCompleted,
    fetchUncompletedSections,
  ]);

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { status, action, step } = data;

      const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
      const finishedActions: string[] = [ACTIONS.CLOSE];

      if (status === STATUS.FINISHED) {
        const section = getSectionBySelfHelpName(currentSelfHelp);
        if (
          section &&
          !!uncompletedSections.find(
            (x) =>
              x.sectionName === section.sectionName && x.page === section.page
          )
        ) {
          setSectionToMark(section);
        }
      }

      if (
        finishedStatuses.includes(status) ||
        finishedActions.includes(action)
      ) {
        dispatch(setCurrentSelfHelp(CurrentSelfHelp.None));
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const tgt = step !== null ? (step.target as string) : "";
      if (tgt) document.querySelector(tgt)?.scrollIntoView();
    },
    [currentSelfHelp, uncompletedSections, dispatch]
  );

  if (
    currentSelfHelp === CurrentSelfHelp.None ||
    currentSplashScreen !== ScreenSplashEnum.None
  ) {
    return <SelfHelpDiv />;
  }

  return (
    <SelfHelpDiv>
      <Joyride
        callback={handleJoyrideCallback}
        steps={steps}
        run={true}
        styles={{
          options: {
            zIndex: 1000,
            primaryColor: defaultTheme.palettes.primary.main,
          },
          buttonBack: {
            color: defaultTheme.palettes.secondary.archived,
          },
        }}
        floaterProps={{
          disableAnimation: true,
        }}
        continuous={true}
        showProgress={true}
        disableScrollParentFix={true}
        disableScrolling={true}
      />
    </SelfHelpDiv>
  );
}
