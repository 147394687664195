import { PaperControl } from "../../../../controls/Paper/PaperControl";
import { DocumentKindsDiv } from "./SC/DocumentKindsDiv";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import {
  addCurrentDocumentKindId,
  removeCurrentDocumentKindId,
  selectAllDocumentKinds,
  selectCurrentDocumentKinds,
  selectDocumentKinds,
  selectIsDocumentKindsEdited,
} from "../../../../app/slices/publisherCategoriesSettingsSlice";
import { ListControl } from "../../List/ListControl";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../../models/selfHelp/selfHelpSection";
import { difference } from "lodash";

interface DocumentKindsPaperProps {
  disabled: boolean;
  isLoading: boolean;
}

export function DocumentKindsPaper(props: DocumentKindsPaperProps) {
  const dispatch = useAppDispatch();
  const categoryDocumentKinds = useAppSelector(selectDocumentKinds);
  const currentCategoryDocumentKinds = useAppSelector(
    selectCurrentDocumentKinds
  );
  const allDocumentKinds = useAppSelector(selectAllDocumentKinds);
  const isShouldBeSorted =
    currentCategoryDocumentKinds === categoryDocumentKinds;
  const editedValues = difference(
    currentCategoryDocumentKinds,
    categoryDocumentKinds
  ).concat(difference(categoryDocumentKinds, currentCategoryDocumentKinds));
  
  const selectedDocumentKinds = allDocumentKinds.filter((dk) =>
    categoryDocumentKinds.includes(dk.id)
  );
  const isEdited = useAppSelector(selectIsDocumentKindsEdited);

  return (
    <PaperControl
      useHeader={true}
      title={"Document kinds"}
      disabled={props.disabled}
      currentSelfHelp={CurrentSelfHelp.CmsDocumentKinds}
      selfHelpPage={SelfHelpPage.Cms}
      selfHelpSectionName={SectionName.CmsDocumentKinds}
      isEdited={isEdited}
    >
      <DocumentKindsDiv id="cms-document-kinds-paper">
        <ListControl
          id={"document-kind"}
          placeholder="Search for a document kind"
          disabled={props.disabled}
          items={allDocumentKinds}
          selectedItems={selectedDocumentKinds}
          isLoading={props.isLoading}
          sortList={isShouldBeSorted}
          editedValues={editedValues}
          selectEventHandler={(e) => dispatch(addCurrentDocumentKindId(e))}
          removeEventHandler={(e) => dispatch(removeCurrentDocumentKindId(e))}
        />
      </DocumentKindsDiv>
    </PaperControl>
  );
}
