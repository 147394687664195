import styled from "styled-components";
import {
  cmaDocumentIcon,
  linkedDocumentIcon,
  noAccessToDocumentIcon,
  serviceDocumentIcon,
} from "../../../../../../icons/DocumentInfoIcons";

export const StyledLinkIcon = styled(linkedDocumentIcon)`
  &.MuiSvgIcon-root {
    font-size: ${(props) => props.theme.typography.fontSizes.large}px;
    color: ${(props) => props.theme.palettes.grayscale.medium};
    padding-right: 2px;
  }
`;

export const StyledLockIcon = styled(noAccessToDocumentIcon)`
  &.MuiSvgIcon-root {
    font-size: ${(props) => props.theme.typography.fontSizes.large}px;
    color: ${(props) => props.theme.palettes.grayscale.medium};
    padding-right: 2px;
  }
`;

export const StyledPublicIcon = styled(cmaDocumentIcon)`
  &.MuiSvgIcon-root {
    font-size: ${(props) => props.theme.typography.fontSizes.large}px;
    color: ${(props) => props.theme.palettes.grayscale.medium};
    padding-right: 2px;
  }
`;

export const StyledElectricalServicesIcon = styled(serviceDocumentIcon)`
  &.MuiSvgIcon-root {
    font-size: ${(props) => props.theme.typography.fontSizes.large}px;
    color: ${(props) => props.theme.palettes.grayscale.medium};
    padding-right: 2px;
  }
`;
