import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { useUserCanEditDocument } from "../../../../app/hooks/permissions/useUserCanEditDocument";
import {
  addNewDocumentTagType,
  clearEditedDocumentTags,
  selectAvailableCurrentDocumentTags,
  selectAvailableDocumentTags,
  selectDocumentTagTypes,
  setCurrentDocumentTagTypes,
  setDocumentTagTypes,
} from "../../../../app/slices/documentMetadataSlice";
import { SectionName } from "../../../../app/slices/models/documentDetailsModels";
import { DiscreetButtonControl } from "../../../../controls/Buttons/DiscreetButtonControl";
import { CircularProgressBar } from "../../../../controls/ProgressIndicators/CircularProgressBar";
import { CurrentSelfHelp } from "../../../../models/CurrentSelfHelpType";
import TagTypes from "../../../../models/documentDetails/Tags/tagTypes";
import Tag from "../../../../models/tag";
import { DetailsSection, DetailsSectionProps } from "../DetailsSection";
import { initialEditableState } from "../../../../app/hooks/permissions/models/state";
import { useDocumentEditedMode } from "../../../../app/hooks/document/useDocumentEditedMode";
import { useFetchTagsByCategory } from "./useFetchTagsByCategory";
import { TagControl } from "./TagControl";
import { AddNewTagPopup } from "./AddNewTag/AddNewTagPopup";
import { useFetchTagsList } from "./useFetchTagsList";
import { selectHasTagsSectionAnyValidationWarnings } from "../../../../app/slices/documentMetadataValidationSlice";

export function TagsSection(props: DetailsSectionProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [isAddNewTagPopupOpen, setIsAddNewTagPopupOpen] = useState(false);
  const [editableState, setEditableState] = useState(initialEditableState);
  const documentTags = useAppSelector(selectAvailableDocumentTags);
  const currentDocumentTags = useAppSelector(
    selectAvailableCurrentDocumentTags
  );
  const documentTagTypes = useAppSelector(selectDocumentTagTypes);

  const { tagSection } = useDocumentEditedMode();
  const { canEditDocument } = useUserCanEditDocument();
  const { fetchTagsByCategoryId, isLoadingCategoryTags, isCategoryTagsError } =
    useFetchTagsByCategory();
  const { fetchTagsList, isLoadingTagsList, tagsList } = useFetchTagsList();
  const isTagSectionWarning = useAppSelector(
    selectHasTagsSectionAnyValidationWarnings
  );

  useEffect(() => {
    setEditableState(canEditDocument(true));
  }, [canEditDocument]);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsAddNewTagPopupOpen(true);
  };

  const handleRefreshView = useCallback(() => {
    fetchTagsByCategoryId();
  }, [fetchTagsByCategoryId]);

  useEffect(() => {
    if (documentTagTypes.length > 0) {
      fetchTagsList(documentTagTypes);
    }
  }, [dispatch, documentTagTypes, fetchTagsList]);

  useEffect(() => {
    if (tagsList) {
      dispatch(setDocumentTagTypes(tagsList));
      dispatch(setCurrentDocumentTagTypes(tagsList));
    }
  }, [dispatch, tagsList]);

  const renderTagMultiValueSections = useCallback(() => {
    return currentDocumentTags
      .filter((t) => t.type === TagTypes.MultiValue)
      .sort((a, b) =>
        a.displayName.toLocaleLowerCase() >= b.displayName.toLocaleLowerCase()
          ? 1
          : -1
      )
      .map((t) => {
        return (
          <TagControl
            editableState={editableState}
            handleRefreshView={handleRefreshView}
            isError={isCategoryTagsError}
            isLoading={isLoadingCategoryTags}
            tag={t}
            key={t.type + t.name}
          />
        );
      });
  }, [
    currentDocumentTags,
    editableState,
    handleRefreshView,
    isCategoryTagsError,
    isLoadingCategoryTags,
  ]);

  const discardTagsChanges = useCallback(() => {
    dispatch(clearEditedDocumentTags());
    dispatch(setCurrentDocumentTagTypes(documentTags));
  }, [dispatch, documentTags]);

  const onAddNewTagConfirm = useCallback(
    (tag: Tag) => {
      dispatch(addNewDocumentTagType(tag));
      setIsAddNewTagPopupOpen(false);
    },
    [dispatch]
  );

  const onAddNewTagCancel = useCallback(() => {
    setIsAddNewTagPopupOpen(false);
  }, []);

  return (
    <DetailsSection
      {...props}
      section={SectionName.Tags}
      name="Tags"
      selfHelpType={CurrentSelfHelp.DetailsTags}
      isWarning={isTagSectionWarning}
      isEdited={tagSection.tagSectionIsEdited}
      onDiscardChanges={discardTagsChanges}
      isEditable={editableState.isEditable}
      notEditableReason={editableState.lockMessage}
    >
      {isLoadingCategoryTags || isLoadingTagsList ? (
        <CircularProgressBar
          size={theme.circularProgress.medium}
          space={theme.circularProgressWrapper.defaultHeight}
          color="secondary"
        />
      ) : (
        <>
          {renderTagMultiValueSections()}
          <AddNewTagPopup
            isOpen={isAddNewTagPopupOpen}
            onConfirmClick={onAddNewTagConfirm}
            onCancelClick={onAddNewTagCancel}
          />
        </>
      )}
      <DiscreetButtonControl
        disabled={isLoadingCategoryTags || !editableState.isEditable}
        disabledTooltipText={editableState.lockMessage}
        id="details-section-tags-add-new-tag-button"
        text="Add new Tag type"
        onClick={handleOnClick}
      />
    </DetailsSection>
  );
}
