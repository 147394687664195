import { useAppSelector } from "../hooks";
import {
  selectIsDocumentFromServiceIntegration,
  selectDocumentDraftType,
} from "../../slices/documentMetadataSlice";

export function useDocumentState() {
  const isDocumentFromServiceIntegration = useAppSelector(
    selectIsDocumentFromServiceIntegration
  );
  const documentDraftType = useAppSelector(selectDocumentDraftType);

  return { isDocumentFromServiceIntegration, documentDraftType };
}
