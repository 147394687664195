import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import LockIcon from "@mui/icons-material/Lock";
import LinkIcon from "@mui/icons-material/Link";
import PublicIcon from "@mui/icons-material/Public";
import DeleteIcon from "@mui/icons-material/Delete";

export const serviceDocumentIcon = ElectricalServicesIcon;
export const noAccessToDocumentIcon = LockIcon;
export const linkedDocumentIcon = LinkIcon;
export const cmaDocumentIcon = PublicIcon;
export const deletedIcon = DeleteIcon;
