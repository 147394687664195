
import AddIcon from "@mui/icons-material/Add";
import { AddTagDiv } from "./SC/AddTagDiv";
import { TextFieldInput } from "../../../../controls/TextField/TextFieldInput";

export function AddTagValue() {
  return (
    <AddTagDiv id="search-input-div">
      <TextFieldInput
        id={"publisher-textfield"}
        label=""
        placeholder="Add new tag value..."
        onClick={() => console.log("search")}
        initialValue={""}
        trimEntries={false}
        icon={<AddIcon />}
        iconText={"Add new publisher"}
      />
    </AddTagDiv>
  );
}
