import { ListItemText } from "@mui/material";
import styled from "styled-components";
export const StyledListItemText = styled(ListItemText)`
  .MuiListItemText-primary {
    font-size: ${(props) => props.theme.typography.fontSizes.headerT1}px;
    font-weight: bold;
  }

  .MuiListItemText-secondary {
    font-size: ${(props) => props.theme.typography.fontSizes.headerT2}px;
  }
`;
