import {
  AutocompleteRenderInputParams,
  CircularProgress,
  TextField,
} from "@mui/material";

interface TextFieldProps {
  isLoading: boolean;
  inputStartAdornment?: React.ReactNode;
  inputEndAdornment?: React.ReactNode;
  params: AutocompleteRenderInputParams;
  label?: string;
}

export function TextFieldControl(props: TextFieldProps) {
  function getInputStartAdornment(params: AutocompleteRenderInputParams) {
    if (props.inputStartAdornment || params.InputProps.startAdornment)
      return (
        <>
          {props.inputStartAdornment}
          {params.InputProps.startAdornment}
        </>
      );
  }

  function getInputEndAdornment(params: AutocompleteRenderInputParams) {
    return (
      <>
        {props.inputEndAdornment}
        {props.isLoading && <CircularProgress color="inherit" size={20} />}
        {params.InputProps.endAdornment}
      </>
    );
  }

  return (
    <TextField
      {...props.params}
      label={props.label}
      variant="outlined"
      InputProps={{
        ...props.params.InputProps,
        startAdornment: getInputStartAdornment(props.params),
        endAdornment: getInputEndAdornment(props.params),
      }}
    />
  );
}
