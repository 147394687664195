import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import WarningPopupQueue from "./warning/WarningPopupQueue";
import { useDuplicateAttachment } from "../../../../../app/hooks/document/useDuplicateAttachment";
import DuplicateAttachmentPopup from "../publish/DuplicateAttachmentPopup";
import { NewRevisionPopup } from "../publish/NewRevisionPopup";
import { usePublishDocumentAction } from "../../../../../app/hooks/document/usePublishDocumentAction";
import { ShowPopups } from "../../../../../models/documentDetails/publishButtonPopupProps";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks/hooks";
import { setDocumentAction } from "../../../../../app/slices/documentMetadataSlice";
import { Actions } from "../../../../../models/documentDetails/actions";
import { IdentityChangeConfirmationDialog } from "../publish/IdentityChangeConfirmationDialog";
import PublishErrorHandler from "../publish/PublishErrorHandler";
import {
  selectHasAnySectionNotVisiblePublishError,
  selectHasCurrentDocumentAnyValidationErrors,
  setIsPublishDocumentValidationOn,
} from "../../../../../app/slices/documentMetadataValidationSlice";
import { DiscardPendingApprovalPopup } from "../publish/DiscardPendingApprovalPopup";

interface PublishButtonPopupsProps {
  id: string;
  userEmail: string;
  setShowPopups: Dispatch<SetStateAction<ShowPopups>>;
  showPopups: ShowPopups;
  setButtonHandlerClicked: Dispatch<SetStateAction<boolean>>;
  buttonHandlerClicked: boolean;
  isApproveButton?: boolean;
}

export function PublishButtonPopups(props: PublishButtonPopupsProps) {
  const dispatch = useAppDispatch();
  const [warningPopupIsOpen, setWarningPopupIsOpen] = useState(false);
  const [newRevisionPopupIsOpen, setNewRevisionPopupIsOpen] = useState(false);
  const [discardPendingApprovalsIsOpen, setDiscardPendingApprovalsIsOpen] =
    useState(false);
  const hasAnyValidationsErrors = useAppSelector(
    selectHasCurrentDocumentAnyValidationErrors
  );
  const hasAnyNonVisibleValidationsErrors = useAppSelector(
    selectHasAnySectionNotVisiblePublishError
  );

  const {
    isDuplicateAttachmentPopupOpen,
    originalDocumentIdentity,
    navigateToOriginalDocumentHandler,
    closePopupHandler: closeDuplicatePopup,
    openPopupHandler: openDuplicatePopup,
  } = useDuplicateAttachment();

  const {
    publishButtonHandler,
    isIdentityChangedPopupOpen,
    onCloseClick,
    onNoClick,
    onYesClick,
    publishingErrors,
  } = usePublishDocumentAction(props.userEmail, props.isApproveButton);

  const onWarningPopupSave = useCallback(() => {
    props.setShowPopups((popups) => ({ ...popups, showWarning: false }));
    setWarningPopupIsOpen(false);
  }, [props]);

  const onWarningPopupClose = useCallback(() => {
    props.setButtonHandlerClicked(false);
    setWarningPopupIsOpen(false);
  }, [props]);

  const onNewRevisionPopupDelete = useCallback(() => {
    props.setShowPopups((popups) => ({
      ...popups,
      showNewRevision: false,
    }));
    dispatch(setDocumentAction(Actions.DeletePreviousRevision));
    setNewRevisionPopupIsOpen(false);
  }, [props, dispatch]);

  const onNewRevisionPopupArchive = useCallback(() => {
    props.setShowPopups((popups) => ({
      ...popups,
      showNewRevision: false,
    }));
    dispatch(setDocumentAction(Actions.ArchivePreviousRevision));
    setNewRevisionPopupIsOpen(false);
  }, [props, dispatch]);

  const onNewRevisionPopupClose = useCallback(() => {
    props.setButtonHandlerClicked(false);
    setNewRevisionPopupIsOpen(false);
  }, [props]);

  const onDuplicatePopupClose = useCallback(() => {
    props.setButtonHandlerClicked(false);
    closeDuplicatePopup();
  }, [props, closeDuplicatePopup]);

  const onDuplicatePopupPublish = useCallback(() => {
    props.setShowPopups((popups) => ({
      ...popups,
      showDuplicateAttachment: false,
    }));
    closeDuplicatePopup();
  }, [props, closeDuplicatePopup]);

  const onDiscardPendingApprovals = useCallback(() => {
    props.setShowPopups((popups) => ({
      ...popups,
      showPendingApprovals: false,
    }));
    setDiscardPendingApprovalsIsOpen(false);
  }, [props]);

  const onDiscardPendingApprovalsClose = useCallback(() => {
    props.setButtonHandlerClicked(false);
    setDiscardPendingApprovalsIsOpen(false);
  }, [props]);

  useEffect(() => {
    if (props.buttonHandlerClicked) {
      dispatch(setIsPublishDocumentValidationOn(true));

      if (props.showPopups.showPendingApprovals) {
        setDiscardPendingApprovalsIsOpen(true);
        return;
      }

      if (props.showPopups.showDuplicateAttachment) {
        openDuplicatePopup();
        return;
      }

      if (props.showPopups.showWarning) {
        setWarningPopupIsOpen(true);
        return;
      }

      if (hasAnyValidationsErrors || hasAnyNonVisibleValidationsErrors) {
        props.setButtonHandlerClicked(false);
        return;
      }

      if (props.showPopups.showNewRevision) {
        setNewRevisionPopupIsOpen(true);
        return;
      }

      if (
        !props.showPopups.showDuplicateAttachment && // eslint-disable-line
        !props.showPopups.showNewRevision && // eslint-disable-line
        !props.showPopups.showWarning // eslint-disable-line
      ) {
        props.setButtonHandlerClicked(false);
        publishButtonHandler();
      }
    }
  }, [
    props,
    openDuplicatePopup,
    publishButtonHandler,
    dispatch,
    hasAnyValidationsErrors,
    hasAnyNonVisibleValidationsErrors,
  ]);

  return (
    <>
      <PublishErrorHandler errors={publishingErrors} />
      <DiscardPendingApprovalPopup
        id={"publish-discard-pending-approval-dialog"}
        isOpen={discardPendingApprovalsIsOpen}
        onCloseClick={onDiscardPendingApprovalsClose}
        onConfirmClick={onDiscardPendingApprovals}
      />
      <IdentityChangeConfirmationDialog
        id={"publish-identity-change-dialog"}
        onYesClick={onYesClick}
        onNoClick={onNoClick}
        onCloseClick={onCloseClick}
        isOpen={isIdentityChangedPopupOpen}
      />
      <DuplicateAttachmentPopup
        id="duplicate-attachment-popup"
        onCloseClick={onDuplicatePopupClose}
        onConfirmClick={navigateToOriginalDocumentHandler}
        onPublishAnywayClick={onDuplicatePopupPublish}
        publishAnywayButtonText="Publish anyway"
        isOpen={isDuplicateAttachmentPopupOpen}
        originalIdentity={originalDocumentIdentity}
      />
      <NewRevisionPopup
        isOpen={newRevisionPopupIsOpen}
        onArchive={onNewRevisionPopupArchive}
        onClose={onNewRevisionPopupClose}
        onDelete={onNewRevisionPopupDelete}
      />
      <WarningPopupQueue
        isOpen={warningPopupIsOpen}
        onSave={onWarningPopupSave}
        onClose={onWarningPopupClose}
      />
    </>
  );
}
